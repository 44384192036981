import React, { useEffect } from 'react';

const VideoPopup = ({ onClose, videoLink }) => {
  useEffect(() => {
    const handleCloseOutside = (event) => {
      const popup = document.querySelector('.popup-content');
      if (popup && !popup.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleCloseOutside);
    return () => {
      document.removeEventListener('mousedown', handleCloseOutside);
    };
  }, [onClose]);

  const videoIdMatch = videoLink.match(
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  );
  const videoId = videoIdMatch ? videoIdMatch[1] : null;

  return (
    <div className="popup-container" style={{ zIndex: 1000 }}>
      <div className="popup-connect">
        <div className="popup-inner">
          <div className="popup-content">
            <div className="close-icon" onClick={onClose}>X</div>
            <div className="video-container">
              {videoId && (
                <iframe
                  width="560"
                  height="315"
                  style={{ alignItems: "center" }}
                  src={`https://www.youtube.com/embed/${videoId}`}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPopup;
