import React from "react";
import styles from "./HeroSection.module.css"; // Import the CSS module

const HeroSection = () => {
  return (
    <section className={styles.heroSection}>
      <div className={styles.googleReviews}>
        <img src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/googleReviews+(1).png" alt="Google Reviews" />
      </div>
      <div className={styles.content}>
        <h1>A Curated Collection of Dubai’s Finest Luxury Properties by BlackBrick</h1>
        <h2>A LIVING PLATFORM</h2>
      </div>
    </section>
  );
};

export default HeroSection;
