import React, { useState, useRef, useEffect } from 'react';
import { authenticateUser } from "./../context/endpoints";

const LoginPopup = ({ onClose, onSubmitStatus }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const popupRef = useRef();

  const handleCloseOutside = (event) => {
    if (!popupRef.current.contains(event.target)) {
      onClose();
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleCloseOutside);
    return () => {
      document.removeEventListener('mousedown', handleCloseOutside);
    };
  }, []);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const data = await authenticateUser(username, password);
      if (data.statusCode === 200) {
        onSubmitStatus(true, data);
      } else {
        onSubmitStatus(false);
      }
      onClose();
    } catch (error) {
      onSubmitStatus(false);
      onClose();
    }
  };

  const handleFormClick = (event) => {
    event.stopPropagation(); // Prevent click event from bubbling up to the popup container
  };

  return (
    <div className="popup-container">
      <div className="popup-connect" onClick={handleCloseOutside}>
        <div ref={popupRef} className="popup-inner" onClick={handleFormClick}>
          <div className="popup-content popup-inner">
            <h3>Login</h3>
            <button className="close-btn" onClick={onClose}>X</button> {/* Close button with "X" icon */}
            <p className="contact-form-instruction">Login with Username or Email.</p>
            <form onSubmit={handleSubmit}>
              <div className="contact-form-top-list">
                <div className="contact-form-list-item">
                  <label htmlFor="username">USERNAME:</label>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="form-input"

                  />
                </div>
                <div className="contact-form-list-item">
                  <label htmlFor="password">PASSWORD:</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="form-input"

                  />
                </div>
              </div>
              <button type="submit" className="submit-btn popup-submit-buttom">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
