import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getSettingsFn } from "../../context/endpoints";
import { Link } from "react-router-dom";
import ErrorMessage from "../loading-error/error";
import Skeleton from "@mui/material/Skeleton";

// Function to generate a random alphanumeric ID
const getId = () => (Math.random() + 1).toString(36).substring(7);

const Collections = () => {
  const [type, setType] = useState("buy");

  // Fetch settings data
  const { isPending, error, data } = useQuery({
    queryKey: ["settingsData"],
    queryFn: getSettingsFn,
  });

  // Assign data to websitesettings if available
  const websitesettings = !isPending && !error ? Object.assign({}, ...data) : {};

  // Render collection cards
  const renderCollectionCards = () => {
    const collections = [
      { name: "golf", image: websitesettings.homecollectioncardimageone },
      { name: "coastal", image: websitesettings.homecollectioncardimagetwo },
      { name: "marina", image: websitesettings.homecollectioncardimagethree },
      { name: "downtown", image: websitesettings.homecollectioncardimagefour },
      { name: "community", image: websitesettings.homecollectioncardimagefive },
      { name: "family homes", image: websitesettings.homecollectioncardimagesix },
      { name: "contemporary homes", image: websitesettings.homecollectioncardimageseven },
      { name: "investment", image: websitesettings.homecollectioncardimageeight },
    ];

    return collections.map((collection) => (
      <div key={getId()} className="cards collection-card-animation">
        <Link to={`/properties/${type}?collection=${collection.name}`}>
          <p>{collection.name}</p>
          <img src={collection.image} alt={collection.name} />
        </Link>
      </div>
    ));
  };

  return (
    <React.Fragment>
      <div className="collection-container">
        <div className="container">
          <div className="collection">
            <h3>view by collection</h3>
            <h4>
              We understand that each person, family and investor is unique and
              has a distinctive set of parameters for finding their perfect
              home. We've curated lifestyle collections of properties to enable
              you to find exactly what you're looking for.
            </h4>
            <p className="collection-details">
              Click on each collection to browse properties based on lifestyle
              destinations or aesthetic sensibilities. If there's a bespoke
              criteria you're looking for, get in touch with our team who will
              be able to help.
            </p>
            <Link to="/contact">
              <p className="collection-button">Get in touch {">"}</p>
            </Link>
            <div className="choose-collection-display">
              <span
                className={type === "buy" ? "active" : undefined}
                onClick={() => setType("buy")}
              >
                BUY
              </span>
              <span style={{ cursor: "unset" }}> &#47;&#47; </span>
              <span
                className={type === "rent" ? "active" : undefined}
                onClick={() => setType("rent")}
              >
                RENT
              </span>
            </div>
          </div>
        </div>
        {isPending ? (
          <div className="container-secondary">
            <div className="collection-card collection-cardx">
              {Array.from({ length: 8 }).map((_, index) => (
                <div className="cards" key={index}>
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                </div>
              ))}
            </div>
          </div>
        ) : error ? (
          <ErrorMessage>{error.message}</ErrorMessage>
        ) : (
          <div className="container-secondary">
            <div className="collection-card">{renderCollectionCards()}</div>
          </div>
        )}
        <div className="home-listings-button">
          <Link to={`/properties/${type}`}>
            <button>view all properties</button>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Collections;
