import React from "react";
import { useQuery } from "@tanstack/react-query";
import ErrorMessage from "../loading-error/error";
import Skeleton from "@mui/material/Skeleton";
import { getSettingsFn, getTeamFn } from "../../context/endpoints";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import People from "../team/people";

const HomeTeam = () => {
  const { isPending, error, data } = useQuery({
    queryKey: ["settingsData"],
    queryFn: () => getSettingsFn(),
  });
  const team = useQuery({
    queryKey: ["TeamData"],
    queryFn: () => getTeamFn(),
  });

  // List of names to prioritize
  const priorityNames = [
    "Matthew J Bate",
    "John D. Brash",
    "Katie Anna Savage",
  ];

  // Helper function to get the priority index for a name
  const getPriorityIndex = (name) => priorityNames.indexOf(name);

  // Sort data by feature, then by priority names within feature true
  const sortedData = team.data?.sort((a, b) => {
      // Prioritize items with feature true
      if (a.feature !== b.feature) {
        return a.feature ? -1 : 1;
      }

      // If both have the same feature value, sort by priority names
      if (a.feature === true && b.feature === true) {
        return getPriorityIndex(a.name) - getPriorityIndex(b.name);
      }

      // If both have the same feature value and it's false, sort by name alphabetically
      return a.name.localeCompare(b.name);
    });

  return (
    <div className="about-container" style={{marginTop:"40px", marginBottom:"50px"}}>
      <div className="container">
        <div className="home-team">
          <h4>the team</h4>
          <h5>
            Meet the lifestyle property experts changing the model of Dubai's Real
            Estate to help you find exactly what you need
          </h5>
        </div>
        <div className="about-people-container" style={{ margin: "0px" }}>
          <div className="about-people" style={{ margin: "0px" }}>
            <h4>Starting Lineup</h4>
            {team.isPending ? (
              <Skeleton variant="rectangular" width="100%" height={200} />
            ) : team.error ? (
              <ErrorMessage>{team.error.message}</ErrorMessage>
            ) : (
              <Swiper
                slidesPerView={2}
                spaceBetween={14}
                breakpoints={{
                  576: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  992: {
                    slidesPerView: 4,
                  },
                  1200: {
                    slidesPerView: 5,
                  },
                  1400: {
                    slidesPerView: 5,
                  },
                }}
                navigation={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
              >
                {sortedData.map((team, index) => (
                  <SwiperSlide key={index}>
                    <People
                      name={team.name}
                      image={team.image}
                      jobTitle={team.jobTitle}
                      email={team.email}
                      AgentID={team.AgentID}
                      isActive={team.isActive}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTeam;
