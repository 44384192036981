import React from "react";
import { Link } from "react-router-dom";

export default function FeaturedPeople({
  index,
  image,
  name,
  jobTitle,
  description,
  email,
  AgentID,
  isActive
}) {
  return (
    <div key={index} className="about-people-card">
      <img src={image} alt={name} />
      <div className="about-people-card-details">
        <h4>{name}</h4>
        <h5>{jobTitle}</h5>
        <p
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        <a href={`mailto:${email}`} rel="noopener noreferrer">
          <h6>{email}</h6>
        </a>
        <Link style= {{textDecoration:"none",top:"50px"}} to={AgentID && isActive ?`/agent/${AgentID}`: ""}>

        {AgentID && isActive &&<h6 style={{margin:"11px 0 25px"}}>VIEW PROFILE</h6>}
        </Link>
      </div>
    </div>
  );
}
