import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";

const JournalArticleCard = ({ article }) => {
  return (
    <div className="journal-article-card">
      <Link to={`/journal/${article._id}`}>
        <img src={article.image[0]} alt="article images" />
        <div>
          <h4>{article.title}</h4>
          <h5>{Moment(article.created).format("DD.MM.YY")}</h5>
        </div>
      </Link>
    </div>
  );
};

export default JournalArticleCard;
