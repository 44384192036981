import React, { useState } from "react";
import styles from "./ImageViewer.module.css";

const ImageViewer = ({ images, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className={styles.viewerOverlay}>
      <div className={styles.viewer}>
        <div className={styles.imageWrapper}>
          <img
            src={images[currentIndex]}
            alt="Property"
            className={styles.viewerImage}
          />
        </div>
        <div className={styles.thumbnailContainer}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className={`${styles.thumbnail} ${
                currentIndex === index ? styles.activeThumbnail : ""
              }`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <button className={styles.prevButton} onClick={prevImage}>
          &lt;
        </button>
        <button className={styles.nextButton} onClick={nextImage}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default ImageViewer;
