import React, {useState,useEffect} from "react";
import styles from "./Header.module.css";
import data from "../../constants/content.json";
import Button from "../Button";

const Header = ({ theme }) => {
  const { lightLogo, darkLogo, navLinks } = data.header;
  const themeClass = theme === "dark" ? styles.dark : styles.light;
  const [scrolled, setScrolled] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  return (
    <header className={`${styles.header} ${scrolled ? "scrolled" : ""}`}>
      <div className={styles.logo}>
        <img src={theme === "light" ? darkLogo : lightLogo} alt="BlackBrick." />

      </div>
      {/* <Button title={"ENQUIRE NOW"} link={"/"} /> */}


      <nav className={styles.navLinks}>
       {/* {theme === "dark" && <Button title={"ENQUIRE NOW"} link={"/"} />} */}

        {/* {navLinks.map((link, index) => (
          <a href={link.href} key={index} className={styles.navItem}>
            {link.text}
          </a>
        ))} */}

      </nav>
    </header>
  );
};

export default Header;
