import React from "react";
import { Link } from "react-router-dom";

export default function People({ name, image, jobTitle, email, AgentID,isActive }) {
  return (
    <div className="about-people-card">
      <Link style= {{textDecoration:"none"}} to={AgentID && isActive ?`/agent/${AgentID}`: ""}>
        <img src={image} alt={name} />
        <h4>{name}</h4>
        <h5>{jobTitle}</h5>
        {AgentID && isActive &&<h6>VIEW PROFILE</h6>}
      </Link>
    </div>
  );
}
