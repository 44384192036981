import React from "react";
import { Link } from "react-router-dom";

export default function JournalFeaturedArticleCard({
  id,
  title,
  description,
  image,
  index,
}) {
  return (
    <div key={index} className="journal-featured-article">
      <div  className="journal-featured-article-card">
        <div className="journal-featured-article-card-details">
          <h1>Featured Article</h1>
          <h2>{title}</h2>
          <p>{description}</p>
          <Link to={`/journal/${id}`}>
            <button>read more</button>
          </Link>
        </div>
        <div className="journal-featured-article-card-image">
          <img src={image[0]} alt="article images" />
        </div>
      </div>
    </div>
  );
}
