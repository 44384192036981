import React, { useEffect, useState } from "react";
import { addDays, format, eachDayOfInterval, isWeekend, addHours, isAfter, startOfDay } from "date-fns";

const PhotographerTimeSlot = ({ formData, photographers, setFormData, getAvailableSlots, formatDate, isEditable }) => {
  const [availableDates, setAvailableDates] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState([]);

  const newPhotographers = photographers.concat([{
    name: "Mazyad Aljaramani",
    image: "https://blackbrickwebsite.s3.me-central-1.amazonaws.com/0526199709+(1).jpg"
  }]);

  useEffect(() => {
    const generateWeekdays = () => {
      const today = new Date();
      const startDate = isEditable ? today : addHours(today, 24); // Do not add padding if editable
      const endDate = addDays(today, 30);
      const allDates = eachDayOfInterval({ start: startOfDay(startDate), end: endDate });
      const weekdays = allDates.filter(date => !isWeekend(date) && isAfter(date, startDate));
      setAvailableDates(weekdays);
    };
    generateWeekdays();
  }, [isEditable]);

  useEffect(() => {
    const filterSlotsForDate = () => {
      if (formData.date) {
        const selectedDate = new Date(formData.date);
        const now = new Date();

        const hoursToAdd = isEditable ? 0: 24
        const hoursAhead = hoursToAdd - Math.max(0, (now - startOfDay(selectedDate)) / (1000 * 60 * 60));

        const availableSlots = getAvailableSlots().filter((slot) => {
          const [hours, minutes] = slot.split(":").map(Number);
          const slotTime = addHours(startOfDay(selectedDate), hours).setMinutes(minutes);

          return isAfter(slotTime, addHours(now, hoursAhead));
        });
        setFilteredSlots(availableSlots);
      }
    };
    filterSlotsForDate();
  }, [formData.date, getAvailableSlots]);

  return (
    <section >
      <h3>Step 4: Photographer and Time Slot</h3>

      <label>Select Photographer</label>
      <div className="photographer-selection">
        {newPhotographers.map((photographer) => (
          <button
            key={photographer.name}
            type="button"
            className={`photographerselection photographer-option ${formData.photographer === photographer.name ? "selected" : ""}`}
            onClick={() => {
              console.log("photographer.name", photographer.name);
              setFormData((prev) => ({ ...prev, photographer: photographer.name }));
            }}
          >
            <img src={photographer.image} alt={photographer.name} className="photographer-image" />
            <span className="photographer-name">{photographer.name}</span>
          </button>
        ))}
      </div>

      <label>Select Date *</label>
      <div className="date-selection-scrollable">
        {availableDates.map((date) => (
          <button
            type="button"
            key={date}
            className={`photographerselection date-box ${formData.date === format(date, "yyyy-MM-dd") ? "selected" : ""}`}
            onClick={() => setFormData((prev) => ({ ...prev, date: format(date, "yyyy-MM-dd") }))}
          >
            <div className="day">{format(date, "EEE")}</div>
            <div className="date">{format(date, "dd")}</div>
            <div className="month-year">{format(date, "MMM yyyy")}</div>
          </button>
        ))}
      </div>

      <label>Select Time Slot *</label>
      <div className="time-slot-buttons">
        {filteredSlots.map((slot) => (
          <button
            type="button"
            key={slot}
            className={`photographerselection time-slot-button ${formData.timeSlot === slot ? "selected" : ""}`}
            onClick={() => setFormData((prev) => ({ ...prev, timeSlot: slot }))}
          >
            {slot}
          </button>
        ))}
      </div>
    </section>
  );
};

export default PhotographerTimeSlot;
