import React from "react";
import styles from "./ShowCase.module.css";

export default function ShowCase() {
  return (
    <div className={styles.newBanner}>
      <div className={styles.content}>
        <h1>A Beautiful way to live</h1>
      </div>
    </div>
  );
}
