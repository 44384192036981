import React,{useEffect} from "react";
import Header from "../components/Header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Bond = () => {

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const appURL = urlParams.get('appURL');

    // Check if appURL exists and if the user is on a mobile device
    if (appURL && navigator.userAgent.match(/Android|iPhone|iPad|iPod/i)) {
      // Try to open the app
      window.location.href = appURL;

      // Timeout to check if the app was successfully opened
      setTimeout(() => {
        // If the app didn't open, redirect to the app store
        window.location.href = getAppStoreLink();
      }, 3000); // Adjust timeout as needed
    }
  }, []);

  const getAppStoreLink = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      return "https://apps.apple.com/ae/app/the-bond/id6449622884";
    } else if (/android/.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=com.bond.tech.bond";
    } else {
      // For other devices, or if unable to determine, return default link
      return "https://blackbrickproperty.com/bond"; // Change to your website's link
    }
  };
  return (
    <>
      <Helmet>
        <title>The Bond - BlackBrick</title>
        <meta
          name="description"
          content="Powered by BlackBrick, The Bond is a community of like minded people energised to connect, socialise and grow. Join us at The Bond"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blackbrickproperty.com/bond" />
        <meta property="og:title" content="The Bond - BlackBrick" />
        <meta
          property="og:description"
          content="Powered by BlackBrick, The Bond is a community of like minded people energised to connect, socialise and grow. Join us at The Bond"
        />
        <meta
          property="twitter:url"
          content="https://blackbrickproperty.com/bond"
        />
        <meta property="twitter:title" content="The Bond - BlackBrick" />
        <meta
          property="twitter:description"
          content="Powered by BlackBrick, The Bond is a community of like minded people energised to connect, socialise and grow. Join us at The Bond"
        />
      </Helmet>
      <Header />

      <div className="bond-cover-image">
        <img
          src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/bond+white+and+black_page-0002.jpg" // Replace with the path to your cover image
          alt="Cover"
        />
      </div>
      <div className="bond-header-container">
        <div className="bond-header-titles">
          <div className="container">
            {/* <h1>The Bond</h1>
            <h2>The best of you is yet to come</h2> */}
          </div>
        </div>
      </div>
      <div className="bond-text">
        <div className="container">
          <h3>
            Join The Bond, a networking community
            <br />
            powered by BlackBrick
          </h3>
          <div className="bond-download-the-app">
            <h2>DOWNLOAD THE APP</h2>
            <div className="stores-badges">
              <a
                href="https://play.google.com/store/apps/details?id=com.bond.tech.bond"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src="/assets/images/google-play-badge.png"
                  alt="Download the app on Google Play"
                />
              </a>
              <a
                href="https://apps.apple.com/ae/app/the-bond/id6449622884"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src="/assets/images/App-Store-Badge.png"
                  alt="Download the app on App store"
                />
              </a>
            </div>
            <h2>FOLLOW US</h2>
              <div className="social-icons">
                <a
                  href="https://www.instagram.com/thebond.ae"
                  rel="noopener noreferrer"
                  target="_blank"
                  class="social-icon"
                >
                  <i class="fab fa-instagram fa-instagram-bond"></i>
                </a>
              </div>
          </div>
        </div>
      </div>
      <div className="bond-get-bondy">
        <div className="container">
          <div className="bond-circles">
            <div className="circle">Get</div>
            <div className="circle">Gather</div>
            <div className="circle">Grow</div>
          </div>
          <h4>
            The Bond is a community of like-minded people, eager to connect,
            grow socially, professionally and financially.
          </h4>
        </div>
      </div>
      <div className="bondy-cards">
        <div className="bond-card">
          <p>Get</p>
          <img
            className="bond-card-img-one"
            src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/get.JPG"
            alt="card"
          />
        </div>
        <div className="bond-card-text">
          <div className="container">
            <h5>
              Get access to a multitude of interesting events & news on The Bond
              app. The Bond is a community where you can exchange opportunities,
              insights and experiences.
            </h5>
          </div>
        </div>
        <div className="bond-card">
          <p>Gather</p>
          <img
            className="bond-card-img-two"
            src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/gather.jpg"
            alt="card"
          />
        </div>
        <div className="bond-card-text">
          <div className="container">
            <h5>
              Gather with like-minded people and connect with a greater
              professional & social network for more insights and inspiration.
              Join or participate in ‘unplugged’ panel discussions & workshops,
              or just find new friends & contacts during ‘off the clock’ social
              events.
            </h5>
          </div>
        </div>
        <div className="bond-card">
          <p>Grow</p>
          <img
            className="bond-card-img-three"
            src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/grow.JPG"
            alt="card"
          />
        </div>
        <div className="bond-card-text">
          <div className="container">
            <h5>
              Grow by enriching your life with new interests, skills and
              contacts while improving your financial position through our
              referral program.
            </h5>
          </div>
        </div>
      </div>
      <div className="bond-bottom">
      <div className="container">
        <h3>
          If you're keen to realise your personal potential and want to be a
          part of a community that empowers you to grow on your terms,
          <br />
          join us at The Bond.
        </h3>
      </div>
  <div className="bond-download-the-app">
    <h2>DOWNLOAD THE APP</h2>
    <div className="stores-badges">
      <a
        href="https://play.google.com/store/apps/details?id=com.bond.tech.bond"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          src="/assets/images/google-play-badge.png"
          alt="Download the app on Google Play"
        />
      </a>
      <a
        href="https://apps.apple.com/ae/app/the-bond/id6449622884"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          src="/assets/images/App-Store-Badge.png"
          alt="Download the app on App store"
        />
      </a>
    </div>
    <h2>FOLLOW US</h2>
    <div className="social-icons">
            <a
                href="https://www.instagram.com/thebond.ae"
                rel="noopener noreferrer"
                target="_blank"
                class="social-icon"
              >
              <i class="fab fa-instagram fa-instagram-bond"></i>
            </a>
          </div>
        </div>

      </div>

      <Footer />
    </>
  );
};

export default Bond;
