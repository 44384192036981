import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getArticlesTopicsFn } from "../../context/endpoints";
import ErrorMessage from "../loading-error/error";
import Skeleton from "@mui/material/Skeleton";

const JournalFilter = (props) => {
  // This code is using the setSearchParams function from the props passed to the component. It is setting the searchParams to the value of the topic passed to it.
  const PostsFiltering = (e) => {
    const topic = e.target.value;
    if (topic) {
      props.setSearchParams({ topic });
    } else {
      props.searchParams({});
    }
  };

  // This code is using the setSearchParams function from the props passed to the component. It is setting the searchParams to an empty string.
  const ResetPostsFiltering = () => {
    const topic = "";
    props.setSearchParams({ topic });
  };

  // This code is using the useQuery hook from a library like React Query. It is fetching data using the getArticlesTopicsFn function and storing the result in the isPending, error, and data variables. The queryKey is used to identify the query.
  const { isPending, error, data } = useQuery({
    queryKey: ["postsData"],
    queryFn: () => getArticlesTopicsFn(),
  });
  return (
    <>
      <div className="container">
        <div className="filtering-header-container">
          {isPending ? (
            <Skeleton variant="text" width="100%">
              <div style={{ paddingTop: "23px" }} />
            </Skeleton>
          ) : error ? (
            <ErrorMessage>{error.message}</ErrorMessage>
          ) : (
            <>
              {data.topics.map((topic, index) => (
                <button
                  key={index}
                  onClick={(e) => PostsFiltering(e, "value")}
                  value={topic}
                >
                  {topic}
                </button>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="properties-selection-filtering">
        <div>
          <h3>{props.articlesCounter} found</h3>
          {props.Topics !== "" && <h4>filter by:&nbsp;{props.Topics}</h4>}
        </div>
        <div>
          {props.Topics !== "" && (
            <h3 onClick={(e) => ResetPostsFiltering(e)}>
              <span>x</span>clear filters
            </h3>
          )}
        </div>
      </div>
    </>
  );
};

export default JournalFilter;
