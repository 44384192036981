import React from "react";
import styles from "./Register.module.css";
import PropertyForm from "../PropertyForm";

export default function Register() {
  return (
    <div className={styles.container}>
      <div className={styles.containerFlex}>
        <div className={styles.content}>
          <h1>Register</h1>
          <h1>Your Interest</h1>
        </div>
        <div>
          <PropertyForm />
        </div>
      </div>
    </div>
  );
}
