import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getSettingsFn, getPortfolioFn } from "../context/endpoints";
import PropertyCard from "../components/property-card";
import Header from "../components/Header";
import Footer from "../components/footer";
import ContactForm from "../components/contact-form";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { Helmet } from "react-helmet";
import ErrorMessage from "../components/loading-error/error";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const Design = () => {
  // This code is using the useQuery hook from a library like React Query. It is fetching data using the getSettingsFn function and storing the result in the isPending, error, and data variables. The queryKey is used to identify the query.
  const websitesettingsData = useQuery({
    queryKey: ["settingsData"],
    queryFn: () => getSettingsFn(),
  });

  // This code to fetch the portfolio data
  const portfolio = useQuery({
    queryKey: ["PortfolioData"],
    queryFn: () => getPortfolioFn(),
  });

  // This code snippet creates an empty object called websitesettings, and if isPending is false and there is no error, it uses Object.assign to merge the contents of all the objects in the data array into the websitesettings object.
  let websitesettings = {};
  if (!websitesettingsData.isPending && !websitesettingsData.error) {
    websitesettings = Object.assign({}, ...websitesettingsData.data);
  }
  return (
    <>
      <Helmet>
        <title>Design & Build - BlackBrick</title>
        <meta
          name="description"
          content="Our Design Team forms a personalised design vision for you, we open the doors to create new unique living spaces that enhance and promote a modern lifestyle while at the same time servicing the market demand for upgrading homes."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://blackbrickproperty.com/design"
        />
        <meta property="og:title" content="Design & Build - BlackBrick" />
        <meta
          property="og:description"
          content="Our Design Team forms a personalised design vision for you, we open the doors to create new unique living spaces that enhance and promote a modern lifestyle while at the same time servicing the market demand for upgrading homes."
        />
        <meta property="og:image" content="/assets/images/design-image3.jpg" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://blackbrickproperty.com/design"
        />
        <meta property="twitter:title" content="Design & Build - BlackBrick" />
        <meta
          property="twitter:description"
          content="Our Design Team forms a personalised design vision for you, we open the doors to create new unique living spaces that enhance and promote a modern lifestyle while at the same time servicing the market demand for upgrading homes."
        />
        <meta
          property="twitter:image"
          content="/assets/images/design-image3.jpg"
        />
      </Helmet>
      <Header />

      <div className="design-header-container">
        <div className="design-header-titles">
          <div className="container">
            <h1>Design & build</h1>
            <h2>
              Our Design Team forms a <i>personalised design vision</i> for you, we
              open the doors to create new <i>unique living spaces</i> that enhance and
              promote a <i>modern</i> lifestyle while at the same time servicing the
              market demand for <i>upgrading homes</i>.
            </h2>
            <p>
              From architectural and interior designs to fit-out, construction
              and staging houses, we take your through the whole process in
              order to create an exquisite home to live in or use as an
              investment opportunity.
            </p>
          </div>
        </div>
      </div>
      <div className="design-container">
       <div className="content-container">
        <div className="container">
          <div className="design-texts">
            <p>
              The Team works with Owners to create the space they desire to call
              home or a design that attracts the optimal sales price maximising
              returns. The property is ultimately delivered through a
              competitive process with our Preferred Fitout Partners, supervised
              and delivered by our Team to ensure the dream becomes reality.
            </p>
            <p>
              Our Design partners international experience and diverse project
              portfolios position them as modernisers in interior design,
              challenging the mainstream trends to conceive innovative spaces
              enhancing consumer experiences. The Teams accomplishments are not
              solely attributed to innovative and progressive designs, however
              it's the human element and the personal relationships that are
              fostered throughout the project cycle that defines success.
              <br />
              &nbsp;
              <br />
              The Team's philosophy embraces that every space is unique, and the
              customers purpose is individual. This is the critical point of
              difference that allows us to create a meaningful design.
            </p>
          </div>
          {websitesettings.isPending ? null : websitesettings.error ? (
            <ErrorMessage>{websitesettings.error.message}</ErrorMessage>
          ) : (
            <div className="design-images">
              <div className="design-image-one">
                <img
                  src={websitesettings.designpageimageone}
                  alt="design images"
                />
              </div>
              <div className="design-image-two">
                <img
                  src={websitesettings.designpageimagetwo}
                  alt="design images"
                />
              </div>
            </div>
          )}
        </div>
        </div>
        <div className="design-our-offering">
          <h2>Added Value</h2>
          <div className="container-secondary">
            {websitesettings.isPending ? (
              <div className="our-offering-card">
                {Array.from({ length: 3 }).map((_, index) => (
                  <Box key={index}>
                    <Skeleton variant="rectangular" width="100%">
                      <div style={{ paddingTop: "357px" }} />
                    </Skeleton>
                  </Box>
                ))}
              </div>
            ) : websitesettings.error ? (
              <ErrorMessage>{websitesettings.error.message}</ErrorMessage>
            ) : (
              <div className="our-offering-card">
                <div className="cards">
                  <h3>planning</h3>
                  <p>
                    With a thorough understanding of the big picture as well as
                    the smallest details, our team of dedicated planning and
                    scheduling partners provide our customers with the
                    confidence of knowing when their fitout or project will be
                    completed, the steps it will take to get there, the cost and
                    budget forecasts, and that their goals will be achieved.
                  </p>
                  <img
                    src={websitesettings.designpagecardimageone}
                    alt="card"
                  />
                </div>
                <div className="cards">
                  <h3>oversight</h3>
                  <p>
                    We work with our Preferred Fitout Partners however we ensure
                    to provide independent and impartial supervision to assist
                    in mitigating potential project risks and to ensure the
                    design intent is preserved. Our Team are able to identify
                    potential issues or failures in a timely manner and protect
                    our clients’ interests throughout the lifecycle of the
                    fitout or construction project by reducing their exposure to
                    risk.
                  </p>
                  <img
                    src={websitesettings.designpagecardimagetwo}
                    alt="card"
                  />
                </div>
                <div className="cards">
                  <h3>interior decoration</h3>
                  <p>
                    We bring our designs to life; we speak to your style. Our
                    FF&E (Furniture, Fixtures & Equipment) Team deliver
                    sophisticated, warm spaces that reflect the multi-cultural
                    backgrounds of our customers with a range from new
                    construction to remodelling. We ensure we have an
                    understanding of the client’s needs in order to create a
                    comfortable and elegant interior that reflects that client's
                    personality and desired lifestyle.
                  </p>
                  <img
                    src={websitesettings.designpagecardimagethree}
                    alt="card"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="second-content-container">
        <div className="container-secondary">
          <div className="design-to-live-container">
            <div className="design-to-live-left">
              <h2>Invest to sell. Design to live.</h2>
              <p>
                Our Team brings bespoke independent owner's representation and
                provides the right advice to property owners, helping them to
                make the right property reinvestment decisions, We assist owners
                to leverage unique opportunities to invest capital into their
                new or existing property asset - by means of making home
                improvements - for the purpose of selling at an increased sales
                price and profit, or to increase the market value of the
                property they call “Home” and enjoy a renewed living experience.
              </p>
              <h3>Design to live</h3>
              <p>
                To create a home is to create memories and create a life that
                you desire and dream for, We know how you feel. From knowing
                you, we understand your latent and immediate needs. So we will
                find you the best home and through design and fitout, help you
                create and enhance the space you will call home.
              </p>
              <h3>Invest to sell</h3>
              <p>
                We know what is at stake. We use our talent, expertise and
                unique business approach to deliver the highest return, A real
                estate asset, whether it be a property investment or the home
                you live in, can increase its market value through thoughtful
                design and fitout works backed by experience and market
                knowledge, Our design team and property advisors guide and
                assist owners in creating a home that inspires the optimal offer
                in the shortest period of time.
              </p>
            </div>
            <div className="design-to-live-right">
              <img
                src="/assets/images/design-image3.jpg"
                alt="design to live images"
              />
            </div>
          </div>
        </div>
        </div>
      </div>
      <ContactForm
        formTitle={"our bespoke service?"}
        formSubtitle={"Interested in"}
      />
      <div className="portfolio-container">
        <div className="container">
          <h3>Portfolio</h3>
          <div className="property-cards-container">
            {portfolio.isPending ? (
              <div className="properties-listings">
                {Array.from({ length: 3 }).map((_, index) => (
                  <Box key={index}>
                    <Skeleton variant="rectangular" width="100%">
                      <div style={{ paddingTop: "259px" }} />
                    </Skeleton>
                    <Skeleton variant="text" width="100%">
                      <div
                        style={{ paddingTop: "65px", marginBottom: "-9px" }}
                      />
                    </Skeleton>
                    <Skeleton variant="text" width="100%">
                      <div style={{ paddingTop: "23px" }} />
                    </Skeleton>
                  </Box>
                ))}
              </div>
            ) : portfolio.error ? (
              <ErrorMessage>{portfolio.error.message}</ErrorMessage>
            ) : (
              <Swiper
                slidesPerView={1}
                spaceBetween={18}
                grabCursor={true}
                loop={true}
                breakpoints={{
                  576: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  992: {
                    slidesPerView: 2,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                  1400: {
                    slidesPerView: 3,
                  },
                }}
                navigation={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
              >
                {portfolio.data.length > 0 &&
                  portfolio.data.map((property, index) => (
                    <SwiperSlide key={index}>
                      <PropertyCard
                        key={index}
                        cardLink={`/portfolio/${property._id}`}
                        cardImage={property.images}
                        cardTitle={property.project_title}
                        cardLocation={property.location}
                        cardBedrooms={property.bedrooms}
                        cardPrice={property.project_budget}
                        priceOnApplication ={property.PriceOnApplication}
                        RefNo={property.RefNo}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
          </div>
        </div>
      </div>
     
      <Footer />
    </>
  );
};

export default Design;
