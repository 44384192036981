import React, { useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import {
  Home,
  RentalsProperties,
  SalesProperties,
  PrivateOffice,
  RentalPropertyDetails,
  SalePropertyDetails,
  RentalPropertyDetailsGallery,
  SalePropertyDetailsGallery,
  PrivatePropertyDetailsGallery,
  Design,
  PortfolioDetails,
  PortfolioDetailsGallery,
  Advisory,
  Journal,
  ArticleDetails,
  ArticleDetailsGallery,
  About,
  Contact,
  Bond,
  NotFound,
  FlyerPreview,
  PrivatePropertyDetails,ImagesPreview,
  AgentProfile,
  TermsAndCondtions,
  BBTermsAndCondtions,
  LandingPage,
  BlogDetails,
  Blogs,
  ShootForm,
  ListPropertForm,
  EventDetails,MediaCoverageList
} from "./Roots";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

// Scroll to top everytime you navigate to a new page
const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const App = () => {
  return (
    <Router>
      <Wrapper>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/properties/rent" element={<RentalsProperties />} />
            <Route path="/properties/buy" element={<SalesProperties />} />
            <Route path="/private/office" element={<PrivateOffice />} />
            <Route
              path="/properties/rent/property/:id"
              element={<RentalPropertyDetails />}
            />
            <Route
              path="/properties/buy/property/:id"
              element={<SalePropertyDetails />}
            />

            <Route
              path="/properties/private/property/:id"
              element={<PrivatePropertyDetails />}
            />
            <Route
              path="/properties/rent/property/:id/gallery"
              element={<RentalPropertyDetailsGallery />}
            />
            <Route
              path="/properties/buy/property/:id/gallery"
              element={<SalePropertyDetailsGallery />}
            />
            <Route path="/agent/:id" element={<AgentProfile />} />
            <Route
              path="/properties/private/property/:id/gallery"
              element={<PrivatePropertyDetailsGallery />}
            />
            <Route path="/design" element={<Design />} />
            <Route path="/portfolio/:id" element={<PortfolioDetails />} />
            <Route
              path="/portfolio/:id/gallery"
              element={<PortfolioDetailsGallery />}
            />
            <Route path="/advisory" element={<Advisory />} />
            <Route path="/journal" element={<Journal />} />
            <Route path="/journal/:id" element={<ArticleDetails />} />
            <Route path="/blogs" element={<Blogs/>} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            
            
            <Route
              path="/journal/:id/gallery"
              element={<ArticleDetailsGallery />}
            />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/media/coverage" element={<ShootForm />} />
            <Route path="/media/coverage/:id" element={<ShootForm />} />

            <Route path="/media/coverages" element={<MediaCoverageList />} />

            <Route path="/bond/terms" element={<TermsAndCondtions />} />
            <Route path="/bb/terms" element={<BBTermsAndCondtions />} />
            <Route path="/landing/page" element={<LandingPage />} />
            <Route path="/landing/page/aboutus" element={<LandingPage />} />
            <Route path="/landing/page/properties" element={<LandingPage />} />
            <Route path="/landing/page/register" element={<LandingPage />} />
            <Route path="/landing/page/reviews" element={<LandingPage />} />
            <Route path="/list/property/form" element={<ListPropertForm />} />

            <Route
              path="/download/:refNo/:propertyType/:flyerType"
              element={<FlyerPreview />}
            />
            <Route
              path="/hidden"
              element={<ImagesPreview />}
            />
             <Route
              path="/event/:title/details"
              element={<EventDetails />}
            />
            <Route path="/bond" element={<Bond />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </QueryParamProvider>
      </Wrapper>
    </Router>
  );
};

export default App;
