import React, {useEffect, useState} from "react";

const ReadMore = ({ h1Content = "", h2Content = "" }) => {

    const [showFullDescription, setFullDescription] = useState(false);
    const [parsedContent, setParsedContent] = useState("");
  
    useEffect(() => {
      // Extract CDATA content using regex
      const cdataRegex = /<!\[CDATA\[(.*?)\]\]>/s;
      const match = h2Content.match(cdataRegex);
  
      if (match && match[1]) {
        setParsedContent(match[1]);
      } else {
        // If CDATA not found, use the entire content
        setParsedContent(h2Content);
      }
    }, [h2Content]);
  
    // Find the first period within the first 300 characters
    const firstPeriodIndex = parsedContent;
    const displayedContent =  firstPeriodIndex <= 300
      ? parsedContent.substr(0, firstPeriodIndex + 1)
      : parsedContent.substr(0, 300);
  
    const showFullDescriptionHandler = () => {
      setFullDescription(!showFullDescription);
    };
  return (
    <div className="container">
      <div className="home-text">
        <h1>
          {h1Content ||
            "BlackBrick sells and rents exclusive homes in the most sought-after Lifestyle Destinations in Dubai. We connect international and local clients to the most luxurious, design-led villas and apartments in Dubai's premium communities."}
        </h1>
        <h2>
            <span dangerouslySetInnerHTML={{ __html: showFullDescription ? parsedContent : displayedContent }} />
        </h2>
          {parsedContent.length > 300 ? (
        <p style ={{cursor:"pointer"}}className="read-more" onClick={showFullDescriptionHandler}>
          Read {showFullDescription ? "less" : "more"}
        </p>
      ) : null}
      </div>
    </div>
  );
}

export default ReadMore;
