import React, { useState } from "react";
import styles from "./PropertyCard.module.css";
import ImageViewer from "./ImageViewer"; // Import the ImageViewer component
import { FaEye } from "react-icons/fa"; // Import an icon from react-icons

const PropertyCard = ({ image, title, bedrooms, location, images }) => {
  const [isViewerOpen, setViewerOpen] = useState(false);

  const handleIconClick = () => {
    console.log("Icon clicked");
    setViewerOpen(true);
  };

  const closeViewer = () => {
    setViewerOpen(false);
  };

  return (
    <div className={styles.card}>
      <img
        src={image}
        alt={title}
        className={styles.cardImage}
      />
      <div className={styles.cardContent}>
        <h3 className={styles.cardTitle}>{title}</h3>
        <p className={styles.cardDetails}>{bedrooms} bedroom villa</p>
        <p className={styles.cardLocation}>{location}</p>
      </div>

      {/* Icon for viewing more images */}
      <div className={styles.iconContainer} onClick={handleIconClick}>
        <FaEye className={styles.viewIcon} /> {/* Icon for viewing images */}
        <span className={styles.iconText}>View More Images</span>
      </div>

      {isViewerOpen && (
        <ImageViewer images={images} onClose={closeViewer} />
      )}
    </div>
  );
};

export default PropertyCard;
