import React, { useEffect } from 'react';
import { Link } from "react-router-dom";


const CustomPopup = ({ 
  onClose,
  onSubmitStatus,
  AgentID,
  Agent,
  AgentEmail,
  AgentContactNumber,
  description,
  showProfileButton= true, 
  facebookLink,
  instagramLink,
  linkedinLink,
  xLink,
  agentProfileExists
}) => {
  useEffect(() => {
    const handleCloseOutside = (event) => {
      // const popup = document.querySelector('.popup-content') || document.querySelector('.popup-container')
      // if (popup && !popup.contains(event.target)) {
      //   onClose();
      // }
    };
    
    // Adjust event listener to capture both mouse clicks and taps
    document.addEventListener('mousedown', handleCloseOutside);
    document.addEventListener('touchstart', handleCloseOutside);    
  }, [onClose]);

  const handleSubmit = async (event, status) => {
    try {
      event.preventDefault();
      // Perform any action with the name and description
      onSubmitStatus(status, { Agent, description });
      onClose();
    } catch (error) {
      onSubmitStatus(false);
      onClose();
    }
  };

  const whatsappContact =(contactNumber)=> {
    // Assuming the contact number is in international format without the plus sign
    const whatsappUrl = `https://wa.me/+${contactNumber}`;
    window.open(whatsappUrl, '_blank');
  }

  return (
    <div className="popup-container">
      <div className="popup-connect">
        <div className="popup-inner">
          <div className="popup-content">
            <h3>Connect with {Agent}</h3>
            <p>Choose your preferred platform from the list below</p>
            <div className="social-icons">
                <a key={1} href={`mailto:${AgentEmail}`} className="social-icon">
                  <img src={`/assets/images/social-icons/message.png`} alt="email" />
                </a>
                <a key={2} href="#"  onClick={()=>whatsappContact(AgentContactNumber)} className="social-icon">
                  <img src={`/assets/images/social-icons/whatsapp.png`} alt="whatsapp" />
                </a>
                <a key={3} href={`tel:${AgentContactNumber}`} className="social-icon">
                    <img src="/assets/images/social-icons/phonecall.png" alt="phone call" />
                </a>
                {facebookLink &&
                <a key={4} href={facebookLink} className="social-icon">
                  <img src="/assets/images/social-icons/facebook.png" alt="facebook" />
                 </a>
                }
                {instagramLink &&
                <a key={5} href={instagramLink} className="social-icon">
                  <img src="/assets/images/social-icons/instagram.png" alt="instagram" />
                 </a>
                }
                {linkedinLink &&
                <a key={6} href={linkedinLink} className="social-icon">
                  <img src="/assets/images/social-icons/linkedin.png" alt="linkedin" />
                 </a>
                }
                {xLink &&
                <a key={7} href={xLink} className="social-icon">
                  <img src="/assets/images/social-icons/x.png" alt="x" />
                 </a>
                }
            </div>
            <div className="button-container">
              <button onClick={(e) => onClose()} className="submit-btn">Close</button>
             {showProfileButton && agentProfileExists &&
                  <Link 
                    to={`/agent/${AgentID}`}
                  >
                  <button className="submit-btn">
                      View Profile
                  </button>
                  </Link>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPopup;
