import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class BuildSection extends Component {
  render() {
    return (
      <div className="container">
        <div className="build-section-container">
          <div className="build-section-texts">
            <h4>design & build</h4>
            <h3>Design and build your own sanctuary</h3>
            <p>
              Through a personalised design vision, we open the doors to create
              new unique living spaces that enhance and promote a modern
              lifestyle while at the same time servicing the market demand for
              upgrading property assets.
            </p>
            <h5>Design</h5>
            <p>
              Our Design partners international experience and diverse project
              portfolios position them as modernisers in interior design,
              challenging the mainstream trends to conceive innovative spaces
              enhancing consumer experiences. The Team's philosophy embraces
              that every space is unique, and the customers purpose is
              individual. This is the critical point of difference that allows
              us to create a meaningful design.
            </p>
            <h5>Planning & Procurement</h5>
            <p>
              With a thorough understanding of the big picture as well as the
              smallest details, our team of dedicated planning and scheduling
              partners provide our customers with the confidence of knowing when
              their fitout or project will be completed, the steps it will take
              to get there, and that their goals will be achieved.
            </p>
            <h5>Oversight</h5>
            <p>
              We work with our Preferred Fitout Partners however we ensure to
              provide independent and impartial supervision to assist in
              mitigating potential project risks and to ensure the design intent
              is preserved. Our Team are able to identify potential issues or
              failures in a timely manner and protect our client's interests
              throughout the lifecycle of the fitout or construction project by
              reducing their exposure to risk.
            </p>
            <h5>Interior Decoration</h5>
            <p>
              We bring our designs to life; we speak to your style. Our FF&E
              (Furniture, Fixtures & Equipment) Team deliver sophisticated, warm
              spaces that reflect the multi-cultural backgrounds of our
              customers with a range from new construction to remodelling. We
              ensure we have an understanding of the client's needs in order to
              create a comfortable and elegant interior that reflects that
              client's personality and desired lifestyle.
            </p>
            <Link to="/design">
              <h6 className="read-more">Read more {">"}</h6>
            </Link>
          </div>
          <div className="build-section-image">
            <img src="/assets/images/home-design.jpg" alt="design and build" />
          </div>
        </div>
      </div>
    );
  }
}
