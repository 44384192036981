import React, { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header";
import Footer from "../footer";
import ContactForm from "../contact-form";
import ErrorMessage from "../loading-error/error";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Link } from "react-router-dom";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { Helmet } from "react-helmet";

export default function PropertyDetailsGallery({ property }) {
  const location = useLocation();
  const type = location.pathname.split("/")[2];

  useEffect(() => {
    if (type === "private"){
      // Disable right-click
      const preventRightClick = (event) => {
        event.preventDefault();
      };

      document.addEventListener('contextmenu', preventRightClick);

      // Disable drag-and-drop
      const preventDragStart = (event) => {
        event.preventDefault();
      };

      document.addEventListener('dragstart', preventDragStart);

      // Clean up event listeners when component unmounts
      return () => {
        document.removeEventListener('contextmenu', preventRightClick);
        document.removeEventListener('dragstart', preventDragStart);
      };
    }
  }, [type]);
 
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isGrid, setIsGrid] = useState(true);
  const [isSlider, setIsSlider] = useState(false);


  // This code to convert the gallery to grid view
  const clickIsGrid = () => {
    setIsGrid((current) => !current);
    setIsSlider((current) => !current);
  };

  const extractVideoId =(videoLink) => {
    // Extract video ID from YouTube link
    const videoIdMatch = videoLink.match(
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );
    if (videoIdMatch && videoIdMatch.length > 1) {
        return videoIdMatch[1];
    } else {
        // Handle invalid YouTube link
        console.error("Invalid YouTube video link:", videoLink);
        return "";
    }
}
  // This code to convert the gallery to slider view
  const clickIsSlider = () => {
    setIsGrid((current) => !current);
    setIsSlider((current) => !current);
  };
  return (
    <>
      <Header />
      {property.isPending ? null : property.error ? (
        <ErrorMessage>{property.error.message}</ErrorMessage>
      ) : (
        <>
          <Helmet>
            <title>{`${
              property.data.MarketingTitle || ""
            } Gallery's - BlackBrick`}</title>
            <meta
              name="description"
              content={`${property.data.MarketingTitle} Gallery's`}
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`https://blackbrickproperty.com/properties/${type}/property/${property.data.RefNo}/gallery`}
            />
            <meta
              property="og:title"
              content={`${property.data.MarketingTitle} Gallery's - BlackBrick`}
            />
            <meta
              property="og:description"
              content={`${property.data.MarketingTitle} Gallery's`}
            />
            <meta property="og:image" content="" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content={`https://blackbrickproperty.com/properties/${type}/property/${property.data.RefNo}/gallery`}
            />
            <meta
              property="twitter:title"
              content={`${property.data.MarketingTitle} Gallery's - BlackBrick`}
            />
            <meta
              property="twitter:description"
              content={`${property.data.MarketingTitle} Gallery's`}
            />
            <meta property="twitter:image" content="" />
          </Helmet>
          <div className="backtoallresults">
            <Link to={`/properties/${type}/property/${property.data.RefNo}`}>
              <h2>
                <span>&#60;</span>back to listing
              </h2>
            </Link>
            <Link to={`/properties/${type}/property/${property.data.RefNo}`}>
              <h3>X</h3>
            </Link>
          </div>
          <div className="gallery-container">
            <div className="container">
              <div className="gallery-switch">
                <div
                  className={
                    isSlider
                      ? "gallery-switch-slider gallery-isGrid"
                      : "gallery-switch-slider"
                  }
                >
                  <h3 onClick={clickIsSlider}>Slider view</h3>
                </div>
                <div
                  className={
                    isGrid
                      ? "gallery-switch-grid gallery-isSlider"
                      : "gallery-switch-grid"
                  }
                >
                  <h3 onClick={clickIsGrid}>Grid view</h3>
                </div>
              </div>
              <Gallery>
                <div
                  className={
                    isSlider
                      ? "gallery-grid-container gallery-isGrid"
                      : "gallery-grid-container"
                  }
                >
                  {property.data.Images?.Image.length > 0 && 
  // First, filter the video and display it
  property.data.Images?.Image.filter(item => item.ImageURL.includes("youtu.be")).slice(0,1).map(
    (item, index) => (
      <div key={`video-${index}`}>
        <iframe
          title="property video"
          style={{ width: '100%', height: '275px' }}
          src={`https://www.youtube.com/embed/${extractVideoId(item.ImageURL)}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    )
  )}

{property.data.Images?.Image.length > 0 && 
  // Then, display the rest of the images (excluding YouTube links)
  property.data.Images?.Image.filter(item => !item.ImageURL.includes("youtu.be")).map(
    (item, index) => (
      <div key={`image-${index}`}>
        <Item
          original={item.ImageURL}
          thumbnail={item.ImageURL}
          width="800"
          height="600"
        >
          {({ ref, open }) => (
            <img
              ref={ref}
              onClick={open}
              src={item.ImageURL}
              alt="property images"
            />
          )}
        </Item>
      </div>
    )
  )}

                </div>
              </Gallery>
              <div
                className={
                  isGrid
                    ? "gallery-slider-container gallery-isSlider"
                    : "gallery-slider-container"
                }
              >
                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  spaceBetween={12}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {property.data.Images?.Image.length > 0 &&
                    property.data.Images?.Image.map((item, index) => (
                      <SwiperSlide key={index}>
                        {item.Title !== "External URL" &&<img src={item.ImageURL} alt="property images" />}
                      </SwiperSlide>
                    ))}
                </Swiper>
                <Swiper
                  onSwiper={setThumbsSwiper}
                  spaceBetween={12}
                  slidesPerView={6}
                  freeMode={true}
                  watchSlidesProgress={true}
                  breakpoints={{
                    576: {
                      slidesPerView: 6,
                    },
                    768: {
                      slidesPerView: 10,
                    },
                    992: {
                      slidesPerView: 10,
                    },
                    1200: {
                      slidesPerView: 12,
                    },
                    1400: {
                      slidesPerView: 12,
                    },
                  }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {property.data.Images?.Image.length > 0 &&
                    property.data.Images?.Image.map((item, index) => (
                      <SwiperSlide key={index}>
                        {item.Title !== "External URL" &&<img src={item.ImageURL} alt="property images" />}
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </div>
          <ContactForm
            formTitle={`${property.data.MarketingTitle}?`}
            formSubtitle={"Interested in"}
          />
        </>
      )}
      <Footer />
    </>
  );
}
