import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getSlidesFn } from "../../context/endpoints";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import { Autoplay, Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import ErrorMessage from "../loading-error/error";
import Skeleton from "@mui/material/Skeleton";
import HomeFilter from "./homeFilter"; // Import the new FilterComponent
import "./ExclusiveSlider.css"; // Scoped CSS file

export default function ExclusiveSlider() {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const [listingType, setListingType] = useState("Buy"); // Buy or Rent
  const [propertyType, setPropertyType] = useState(""); // Property Type
  const [community, setCommunity] = useState(""); // Community
  const [minPrice, setMinPrice] = useState(""); // Min Price
  const [maxPrice, setMaxPrice] = useState(""); // Max Price
  const [minBedrooms, setMinBedrooms] = useState(""); // Min Bedrooms
  const [maxBedrooms, setMaxBedrooms] = useState(""); // Max Bedrooms

  const { isPending, error, data } = useQuery({
    queryKey: ["slidesData"],
    queryFn: () => getSlidesFn(),
  });

  const onSearch = () => {
    // This function will trigger the filters and re-render the component
    console.log("Search triggered with filters:", {
      listingType,
      propertyType,
      community,
      minPrice,
      maxPrice,
      minBedrooms,
      maxBedrooms,
    });
    const params = new URLSearchParams({
      listingType,
      category:propertyType,
      location: community,
      minPrice: minPrice || "", // Default to an empty string if no value
      maxPrice: maxPrice || "",
      minBedrooms: minBedrooms || "",
      maxBedrooms: maxBedrooms || "",
    });

    // Navigate to the /properties/buy route with the query params
    navigate(`/properties/${listingType.toLowerCase()}?${params.toString()}`);
  };

  
  return (
    <div className="exclusive-slider-wrapper">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        grabCursor={true}
        loop={true}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        navigation={false}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >
        {data?.length > 0 &&
          data?.map((item, index) => (
            <SwiperSlide key={index}>
              {item.image.length > 0 && (
                <img src={item.image} alt="slider images" className="slider-image" />
              )}
              <div className="slide-item">
                <span className="slide-item-area">
                  {item.subtitle !== "" && <h4>{item.subtitle}</h4>}
                  {item.title !== "" && <h3>{item.title}</h3>}
                  <p>
                    {item.bedrooms !== "" && (
                      <>
                        {item.bedrooms}
                        <br />
                      </>
                    )}
                    {item.description !== "" && (
                      <>
                        {item.description}
                        <br />
                      </>
                    )}
                    {item.address !== "" && (
                      <>
                        {item.address}
                        <br />
                      </>
                    )}
                    {item.price !== "" && <>{item.price}</>}
                  </p>
                  {item.buttontext !== "" && (
                    <Link to={item.buttonlink}>
                      <button>{item.buttontext}</button>
                    </Link>
                  )}
                </span>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
      {/* Filters UI positioned after the slider items */}
      <HomeFilter
            listingType={listingType}
            setListingType={setListingType}
            propertyType={propertyType}
            setPropertyType={setPropertyType}
            community={community}
            setCommunity={setCommunity}
            minPrice={minPrice}
            setMinPrice={setMinPrice}
            maxPrice={maxPrice}
            setMaxPrice={setMaxPrice}
            minBedrooms={minBedrooms}
            setMinBedrooms={setMinBedrooms}
            maxBedrooms={maxBedrooms}
            setMaxBedrooms={setMaxBedrooms}
            onSearch={onSearch} // Search callback
          />
    </div>
  );
}
