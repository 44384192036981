import React, { useEffect, useRef, useState } from 'react';

function GoogleMap({ onSaveLink }) {
  const mapRef = useRef(null);
  const searchBoxRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [markerPosition, setMarkerPosition] = useState({ lat: 25.276987, lng: 55.296249 }); // Initial position (Dubai)
  const markerRef = useRef(null); // Use useRef for the marker to avoid re-rendering

  // Load the Google Maps script
  useEffect(() => {
    if (!window.google) {
      // Load the Google Maps script dynamically with Places API
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCr8gUuxHjGaun4OPFzy6xyXGYVXAKfDBI&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => setIsLoaded(true); // Set state when loaded
      script.onerror = (e) => console.error("Error loading Google Maps script", e); // Handle loading errors
      document.head.appendChild(script);
    } else {
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isLoaded && window.google) {
      const google = window.google;
      const map = new google.maps.Map(mapRef.current, {
        center: markerPosition,
        zoom: 14,
      });

      // Create the marker and make it draggable
      const marker = new google.maps.Marker({
        position: markerPosition,
        map,
        draggable: true,
      });

      markerRef.current = marker; // Store marker in ref

      // Update the position state and map link when the marker is dragged
      marker.addListener('dragend', () => {
        const position = marker.getPosition();
        const newMarkerPosition = { lat: position.lat(), lng: position.lng() };
        setMarkerPosition(newMarkerPosition); // Update state with new position

        const mapLink = `https://www.google.com/maps?q=${newMarkerPosition.lat},${newMarkerPosition.lng}`;
        console.log("New map link after drag:", mapLink);
        onSaveLink(mapLink); // Pass link back to parent
      });

      // Create the autocomplete search box
      const input = searchBoxRef.current;
      if (!input) {
        console.error("Search input element is not found!");
        return;
      }

      const autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.bindTo('bounds', map);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          // Center the map on the selected place
          map.panTo(place.geometry.location);
          map.setZoom(15);

          // Move the marker to the selected place
          marker.setPosition(place.geometry.location);
          setMarkerPosition({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });

          // Update the map link based on the new marker position
          const mapLink = `https://www.google.com/maps/@${place.geometry.location.lat()},${place.geometry.location.lng()},15z`;
          console.log("New map link after search box selection:", mapLink);
          onSaveLink(mapLink);
        } else {
          console.error("Place does not have geometry:", place);
        }
      });
    }
  }, [isLoaded, markerPosition, onSaveLink]);

  return (
    <div style={{ position: 'relative' }}>
      {/* Search box */}
      <input
        ref={searchBoxRef}
        type="text"
        placeholder="Search for a place"
        style={{
          position: 'absolute',
          top: '10px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 5,
          padding: '10px',
          width: '250px',
          borderRadius: '5px',
          border: '1px solid #ccc',
        }}
      />
      {/* Google Map container */}
      <div ref={mapRef} style={{ marginTop: '60px', height: '400px', width: '100%' }} />
    </div>
  );
}

export default GoogleMap;
