import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/footer";
import GoogleMap from "../components/googleMap";
import PhotographerTimeSlot from "../components/photographerTimeSlot";

import "./shootForm.css";
import { getTeamFn,
  getMediaCoverageDetails,
  submitBooking,getAllApprovedBookings,
  updateBooking

} from "../context/endpoints";
import { useQuery } from "@tanstack/react-query";
import { format, addDays } from "date-fns"; // Import from date-fns

const uniqueSellingPointsList = [ "Sea View", "Fully Furnished","Architecture","Landscaped Garden","Pool","Views","Living Room","Kitchen","Staircase/Atrium","Master Bedroom","Gym","Entertainment"
];

const ShootForm = () => {

  const { id } = useParams(); // Grab the ID from the URL
  const location = useLocation(); // Get navigation state

    const [formData, setFormData] = useState({
        advisor: "",
        advisorFirstName: "",
        advisorLastName: "",
        listingStatus: "",
        isExclusive: false,
        isVacant: false,
        country: "UAE",
        community: "",
        subCommunity: "",
        streetNumber: "",
        floorNumber: "",
        unitNumber: "",
        googleMapsLink: "", // New Google Maps link field
        area: "",            // New Area selection field
        listingType: "",
        propertyType: "",
        bedrooms: "",
        askingPrice: "",
        uniqueSellingPoints: [],
        accessInfo: "",
        additionalNotes: "",
        photographer: "",
        date: "",
        timeSlot: "",
      });

  

  const [submissionSuccess, setSubmissionSuccess] = useState(false); // New state for submission success

  const [selectAllUSP, setSelectAllUSP] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const { data: teamData } = useQuery({
    queryKey: ["TeamData"],
    queryFn: () => getTeamFn(),
  });

  const [isEdit, setIsEdit] = useState(false);

  // Set edit mode and populate form data if we have an ID
 // Fetch data from API if in edit mode
    useEffect(() => {
      if (id) {
        setIsEdit(true);
        getMediaCoverageDetails(id) // Call the API with the ID
          .then((data) => {
            setFormData(data); // Set the data to formData
          })
          .catch((error) => {
            console.error("Error fetching shoot data:", error);
          });
      }
    }, [id]);

  const advisors = teamData?.filter((x) => x.advisor) || [];
  const photographers = teamData?.filter((x) => x.photographer) || [];

  const { data: bookingsData } = useQuery({
    queryKey: ["BookingsData"],
    queryFn: () => getAllApprovedBookings(),
  });


  const handleSaveMapLink = (link) => {
    setFormData((prev) => ({ ...prev, googleMapsLink: link }));
  };

  const validateStep = () => {
    switch (step) {
      case 1:
        if (!formData.advisor) {
          setErrorMessage("Please select an advisor to proceed.");
          return false;
        }
        break;
      case 2:
        if (!formData.listingStatus.length || !formData.isExclusive || !formData.isVacant) {
          setErrorMessage("Please fill all required fields (Listing Status, Is Exclusive, Is Vacant).");
          return false;
        }
        break;
      case 3:
        if (!formData.community ) {
          setErrorMessage("Please complete Community, Floor Number, and Map Link.");
          return false;
        }
        break;
      case 4:
        if (!formData.photographer || !formData.date || !formData.timeSlot) {
          setErrorMessage("Please select a photographer, date, and time slot.");
          return false;
        }
        break;
      case 5:
        if (!formData.uniqueSellingPoints.length || !formData.bedrooms || !formData.askingPrice) {
          setErrorMessage("Please complete Unique Selling Points, Bedrooms, and Asking Price.");
          return false;
        }
        break;
      default:
        return true;
    }
    setErrorMessage("");
    return true;
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
  
    // For single selection checkboxes
    if (type === "checkbox") {
      // Manage the checkboxes for single selection
      if (name === "listingStatus") {
        setFormData((prev) => ({ ...prev, listingStatus: value }));
      } else if (name === "isExclusive") {
        setFormData((prev) => ({ ...prev, isExclusive: value }));
      } else if (name === "isVacant") {
        setFormData((prev) => ({ ...prev, isVacant: value }));
      } else if (name === "uniqueSellingPoints") {
        const newUSP = formData.uniqueSellingPoints.includes(value)
          ? formData.uniqueSellingPoints.filter((usp) => usp !== value)
          : [...formData.uniqueSellingPoints, value];
        setFormData({ ...formData, uniqueSellingPoints: newUSP });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSelectAllUSP = () => {
    setSelectAllUSP(!selectAllUSP);
    setFormData((prevData) => ({
      ...prevData,
      uniqueSellingPoints: !selectAllUSP ? uniqueSellingPointsList : [],
    }));
  };


  const handleAdvisorChange = (advisor) => {
    setFormData((prev) => ({ ...prev, advisor: advisor.name }));
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const getAvailableSlots = () => {
    const bookedSlots = bookingsData[formData.date]?.[formData.photographer] || [];
    
    // Generate time slots from 9 AM to 6 PM in 2-hour increments
    const allSlots = ["9:00", "11:00", "13:00", "15:00"]; // 4 slots from 9 AM to 6 PM
    return allSlots.filter((slot) => !bookedSlots.includes(slot));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateStep()) return;

    // Start loading state
    setIsLoading(true);

    try {
        let response;

        // If on the last step, proceed to submit or update the booking
        if (step === 5) {
            if (isEdit) {
                response = await updateBooking(id, formData);
            } else {
                response = await submitBooking(formData);
            }
            console.log("Form data submitted successfully:", response);
            setSubmissionSuccess(true);
            setErrorMessage(""); // Clear any existing error message
        } else {
            // Move to the next step if not on the last step
            setStep((prevStep) => prevStep + 1);
        }
    } catch (error) {
        console.error("Error submitting booking:", error);
        setErrorMessage("Failed to submit booking. Please try again.");
    } finally {
        // End loading state
        setIsLoading(false);
    }
};


  const handlePrevious = () => {
    setStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const getAvailableDates = () => {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < 7; i++) {
      dates.push(addDays(today, i)); // Add days incrementally
    }
    return dates;
  };
  
  // Function to format dates
  const formatDate = (date, formatStr) => format(date, formatStr);
  return (
    <>
      <Helmet>
        <title>Property Shoot Form</title>
      </Helmet>
      <Header />
      <div className="form-container-shoot">
        {submissionSuccess ? ( // Show thank you message on successful submission
        <div className="thank-you-message">
            <h3>Thank you for your submission!</h3>
            <p>Your booking request has been successfully submitted. We will get in touch with you soon.</p>
        </div>
      ) : (
        <>
        <h2>Property Shoot Form</h2>
        <form onSubmit={handleSubmit}>
        {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message */}
          {step === 1 && (
            <section>
              <h3>Step 1: Advisor's Details</h3>
              <label>Select Advisor *</label>
              <div className="custom-dropdown" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                <div className="dropdown-header">
                  {formData.advisor ? (
                    <div className="selected-advisor">
                      <img
                        src={advisors.find((a) => a.name === formData.advisor)?.image}
                        alt="Advisor"
                        className="advisor-avatar"
                      />
                      <p>{formData.advisor}</p>
                    </div>
                  ) : (
                    <span>Select Advisor</span>
                  )}
                </div>
                {isDropdownOpen && (
                  <div className="dropdown-list">
                    {advisors.map((advisor) => (
                      <div
                        key={advisor.name}
                        className="dropdown-item"
                        onClick={() => handleAdvisorChange(advisor)}
                      >
                        <img src={advisor.image} alt="Advisor" className="advisor-avatar" />
                        <span>{advisor.name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </section>
          )}
            {step === 2 && (
                <section
                >
                    <h3>Step 2: Listing Information</h3>
                    
                    <label
                    >Listing Status *</label>
                    <div>
                    <label
                      className="listing-checkbox"
                    >
                        <input
                        type="checkbox"
                        className="listing-checkbox"
                        name="listingStatus"
                        value="Existing"
                        checked={formData.listingStatus === "Existing"}
                        onChange={handleChange}
                        />
                        Existing
                    </label>
                    <label
                        className="listing-checkbox"
                    >
                        <input
                        type="checkbox"
                        name="listingStatus"
                        value="New"
                        checked={formData.listingStatus === "New"}
                        onChange={handleChange}
                        />
                        New
                    </label>
                    </div>

                    <label>Is Exclusive *</label>
                    <div>
                    <label
                        className="listing-checkbox"
                    >
                        <input
                        type="checkbox"
                        name="isExclusive"
                        value="Yes"
                        checked={formData.isExclusive === "Yes"}
                        onChange={handleChange}
                        />
                        Yes
                    </label>
                    <label
                        className="listing-checkbox"
                    >
                        <input
                        type="checkbox"
                        name="isExclusive"
                        value="No"
                        checked={formData.isExclusive === "No"}
                        onChange={handleChange}
                        />
                        No
                    </label>
                    </div>

                    <label>Is Vacant *</label>
                    <div>
                    <label
                        className="listing-checkbox"
                    >
                        <input
                        type="checkbox"
                        name="isVacant"
                        value="Vacant"
                        checked={formData.isVacant === "Vacant"}
                        onChange={handleChange}
                        />
                        Yes
                    </label>
                    <label
                        className="listing-checkbox"
                    >
                        <input
                        type="checkbox"
                        name="isVacant"
                        value="Occupied"
                        checked={formData.isVacant === "Occupied"}
                        onChange={handleChange}
                        />
                        Occupied
                    </label>
                    </div>
                </section>
                )}

          {step === 3 && (
            <section>
              <h3>Step 3: Property Details</h3>
              <label>Community *</label>
              <input
                type="text"
                name="community"
                value={formData.community}
                onChange={handleChange}
                required
              />
              <label>Sub-Community</label>
              <input
                type="text"
                name="subCommunity"
                value={formData.subCommunity}
                onChange={handleChange}
              />
              <label>Street Number</label>
              <input
                type="text"
                name="streetNumber"
                value={formData.streetNumber}
                onChange={handleChange}
              />
              <label>Floor Number *</label>
              <input
                type="text"
                name="floorNumber"
                value={formData.floorNumber}
                onChange={handleChange}
              />
              <label>Select Area *</label>
                  <GoogleMap onSaveLink={handleSaveMapLink} />
            </section>
          )}
        {step === 4 && (
            <PhotographerTimeSlot
                isEditable = {isEdit}
                formData={formData}
                photographers={photographers}
                setFormData={setFormData}
                getAvailableDates={getAvailableDates}
                getAvailableSlots={getAvailableSlots}
                formatDate={formatDate}
            />
            )}
          {step === 5 && (
            <section>
              <h3>Step 5: Additional Information</h3>
              <label>Property Type *</label>
              <select
                name="propertyType"
                value={formData.propertyType}
                onChange={handleChange}
                required
              >
                <option value="">Select Property Type</option>
                <option value="Sale">Sale</option>
                <option value="Rental">Rental</option>
              </select>

              <label>Listing Type *</label>
              <select
                name="listingType"
                value={formData.listingType}
                onChange={handleChange}
                required
              >
                <option value="">Select Lisitng Type</option>
                <option value="Villa">Villa</option>
                <option value="Townhouse">Townhouse</option>
                <option value="Apartment">Apartment</option>
                <option value="Commercial">Commercial</option>
                <option value="Penthouse">Penthouse</option>
              </select>
              <label
              >Unique Selling Point *</label>

              <label
                className="listing-checkbox"
              >
                <input
                  type="checkbox"
                  checked={selectAllUSP}
                  onChange={handleSelectAllUSP}
                />
                Select All
              </label>
              {uniqueSellingPointsList.map((usp) => (
                <label
                className="listing-checkbox"
                key={usp}>
                  <input
                    type="checkbox"
                    name="uniqueSellingPoints"
                    value={usp}
                    checked={formData.uniqueSellingPoints.includes(usp)}
                    onChange={handleChange}
                  />
                  {usp}
                </label>
              ))}

              <label>Bedrooms *</label>
              <input
                type="number"
                name="bedrooms"
                value={formData.bedrooms}
                onChange={handleChange}
                required
              />
              <label>Asking Price *</label>
              <input
                type="number"
                name="askingPrice"
                value={formData.askingPrice}
                onChange={handleChange}
                required
              />
               <label>
                    Access Information (Location pin, Gate Code, etc)
                    <textarea
                        className="shootform-textarea"
                        name="accessInfo"
                        value={formData.accessInfo}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Additional Notes
                    <textarea
                        className="shootform-textarea"
                        name="additionalNotes"
                        value={formData.additionalNotes}
                        onChange={handleChange}
                    />
                </label>
            </section>
          )}
          <div className="form-actions">
            {step > 1 && <button type="button" onClick={handlePrevious}>Previous</button>}
            <button type="submit" disabled={isLoading}>
            {isLoading && step === 5?
               'Submitting...' :
                step === 5 && !isLoading  && isEdit ? "Update" : step === 5 && !isLoading  && !isEdit? "Submit" : 'Next'}
            </button>
          </div>
        </form>
        </>
       )}
      </div>
      <Footer />
    </>
  );
};

export default ShootForm;