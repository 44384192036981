import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/footer";
import JournalFilter from "../components/journal/journal-filter";
import JournalArticleCard from "../components/journal/journal-article-card";
import JournalFeaturedArticleCard from "../components/journal/journal-featured-article-card";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getArticlesFn } from "../context/endpoints";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ErrorMessage from "../components/loading-error/error";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { useInView } from "react-intersection-observer";

const Journal = () => {
  const { ref, inView } = useInView();
  const [searchParams, setSearchParams] = useSearchParams();
  const Topics = searchParams.get("topic") || "";

  // This code to fetch the article data with infinite scrolling by using the useInfiniteQuery hook from a library React Query. by topics and page number
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["postsTopicsData", Topics],
    queryFn: ({ pageParam = 1 }) => getArticlesFn(Topics, pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.page < lastPage.pages) {
        return lastPage?.page + 1;
      }
      return undefined;
    },
  });

  // This code is for infinite scrolling to fetch the next page
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  // This code is for setting the number of articles
  let articlesCounter = "0";
  if (status === "success") {
    articlesCounter = data.pages[0].articlesFound;
  }

  // This code is for loading skeleton when data is loading
  const loadingSkeleton = Array.from({ length: 8 }).map((_, index) => (
    <Box key={index}>
      <Skeleton variant="rectangular" width="100%">
        <div style={{ paddingTop: "234px", marginBottom: "4px" }} />
      </Skeleton>
      <Skeleton variant="text" width="100%">
        <div style={{ paddingTop: "40px" }} />
      </Skeleton>
    </Box>
  ));

  return (
    <>
      <Helmet>
        <title>Journal - BlackBrick</title>
        <meta
          name="description"
          content="A selection of ponderings and discoveries from the BlackBrick team"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://blackbrickproperty.com/journal"
        />
        <meta property="og:title" content="Journal - BlackBrick" />
        <meta
          property="og:description"
          content="A selection of ponderings and discoveries from the BlackBrick team"
        />
        <meta property="og:image" content="/logo512.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://blackbrickproperty.com/journal"
        />
        <meta property="twitter:title" content="Journal - BlackBrick" />
        <meta
          property="twitter:description"
          content="A selection of ponderings and discoveries from the BlackBrick team"
        />
        <meta property="twitter:image" content="/logo512.png" />
      </Helmet>
      <Header />

      <div className="journal-header-container">
        <div className="journal-header-titles">
          <h1>journal</h1>
          <h2>
            A selection of ponderings and discoveries from the BlackBrick team
          </h2>
        </div>
        <JournalFilter
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          Topics={Topics}
          articlesCounter={articlesCounter}
        />
      </div>
      <div className="journal-container container-secondary">
        <div className="journal-article-container">
          {status === "pending" || isFetching ? (
            <>{loadingSkeleton}</>
          ) : status === "error" ? (
            <ErrorMessage>{error.message}</ErrorMessage>
          ) : (
            <>
              {data.pages.map((group, i) => (
                <React.Fragment key={i}>
                  {group.journals.map((article, index) =>
                    article.feature === true ? (
                      <JournalFeaturedArticleCard
                        key={index}
                        id={article._id}
                        title={article.title}
                        description={article.short_description}
                        image={article.image}
                      />
                    ) : (
                      <JournalArticleCard key={index} article={article} />
                    )
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        <p ref={ref}></p>
        {isFetchingNextPage && <>{loadingSkeleton}</>}
      </div>
      <Footer />
    </>
  );
};

export default Journal;
