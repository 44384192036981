import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getTestimoniesFn } from "../context/endpoints";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import ErrorMessage from "./loading-error/error";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

export default function Testimonial({color="#d1cbc3"}) {
  // This code is using the useQuery hook from a library like React Query. It is fetching data using the testimoniesData function and storing the result in the isPending, error, and data variables. The queryKey is used to identify the query.
  const { isPending, error, data } = useQuery({
    queryKey: ["testimoniesData"],
    queryFn: () => getTestimoniesFn(),
  });
  return (
    <div style = {{backgroundColor: color}} className="testimonial">
      <div className="container">
        <h4>testimonial</h4>
        {isPending ? (
          <Box>
            {Array.from({ length: 5 }).map((_, index) => (
              <Skeleton key={index} variant="text" width="100%">
                <div style={{ paddingTop: "23px" }} />
              </Skeleton>
            ))}
            <Skeleton variant="text" width="100%">
              <div style={{ paddingTop: "23px", margin: "35px 0 30px" }} />
            </Skeleton>
          </Box>
        ) : error ? (
          <ErrorMessage>{error.message}</ErrorMessage>
        ) : (
          <Swiper
            autoHeight={true}
            navigation={true}
            grabCursor={true}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            {data.length > 0 &&
              data.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="testimonies">
                    <p>{item.text}</p>
                    <h5>
                      {item.name !== "" && <>{item.name}, </>}
                      {item.propertyName !== "" && <>{item.propertyName}</>}
                    </h5>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        )}
      </div>
    </div>
  );
}
