import React, { useState, useRef, forwardRef } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { userEnquiry } from "./../context/endpoints";

const ContactForm = ({ formTitle, formSubtitle }) => {
  const form = useRef();
  const [open, setOpen] = useState(false);
  const [opensec, setOpensec] = useState(false);
  // EmailJS handler
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  // Alert function
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // This function to close the snackbar
  const handleClose = () => {
    setOpen(false);
    setOpensec(false);
  };

  const sendEmail = async (data) => {
    try {
      // Manually create a data object
      const emailData = {
        firstname: data.firstname,
        surname: data.surname,
        phone: data.phone,
        email: data.email,
        message: data.message,
        url: window.location.href, // Append the current URL
      };
  
      // Await the userEnquiry function call
      // await userEnquiry(`${data.firstname} ${data.surname}`, data.email, data.phone, data.message);
  
      // Send the form data via EmailJS
      const result = await emailjs.send(
        "service_vj32mir",
        "template_g3ipcra",
        emailData,
        "DFn6ZFihQCr8wgqwz"
      );
  
      console.log(result.text);
      setOpen(true);
      form.current.reset();
    } catch (error) {
      console.log(error.text);
      setOpensec(true);
    }
  };
  

  return (
    <div id="ContactForm" className="contact-form-container">
      <div className="container">
        <p>
          {formSubtitle} {formTitle?.replace(/\|/g, " | ")}
        </p>
        <form ref={form} onSubmit={handleSubmit(sendEmail)}>
          <div className="contact-form-top-list">
            <div className="contact-form-list-item">
              <label htmlFor="firstname">first name:</label>
              <input
                {...register("firstname", {
                  required: "First name is Required",
                  minLength: {
                    value: 3,
                    message: "First name must be at least 3 characters long",
                  },
                  maxLength: {
                    value: 15,
                    message: "First name must be at most 15 characters long",
                  },
                })}
                type="text"
                name="firstname"
              />
              {errors.firstname && <p className="error-message">{errors.firstname.message}</p>}
              {!errors.firstname && <p className="placeholder-error-message">&nbsp;</p>}
            </div>
            <div className="contact-form-list-item">
              <label htmlFor="surname">surname:</label>
              <input
                {...register("surname", {
                  required: "Surname is Required",
                  minLength: {
                    value: 3,
                    message: "Surname must be at least 3 characters long",
                  },
                  maxLength: {
                    value: 15,
                    message: "Surname must be at most 15 characters long",
                  },
                })}
                type="text"
                name="surname"
              />
              {errors.surname && <p className="error-message">{errors.surname.message}</p>}
              {!errors.surname && <p className="placeholder-error-message">&nbsp;</p>}
            </div>
            <div className="contact-form-list-item">
              <label htmlFor="phone">phone no.</label>
              <input
                {...register("phone", {
                  required: "Phone Number is Required",
                  minLength: {
                    value: 10,
                    message: "Phone Number must be at least 10 characters long",
                  },
                  maxLength: {
                    value: 15,
                    message: "Phone Number must be at most 15 characters long",
                  },
                })}
                type="text"
                name="phone"
              />
              {errors.phone && <p className="error-message">{errors.phone.message}</p>}
              {!errors.phone && <p className="placeholder-error-message">&nbsp;</p>}
            </div>
          </div>
          <div className="contact-form-bottom-list">
            <div className="contact-form-list-item">
              <label htmlFor="email">email address:</label>
              <input
                {...register("email", {
                  required: "Email is Required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Email must be valid",
                  },
                })}
                type="email"
                name="email"
              />
              {errors.email && <p className="error-message">{errors.email.message}</p>}
              {!errors.email && <p className="placeholder-error-message">&nbsp;</p>}
            </div>
            <div className="contact-form-list-item">
              <label htmlFor="message">message:</label>
              <input
                {...register("message", {
                  required: "Message is Required",
                })}
                type="text"
                name="message"
              />
              {errors.message && <p className="error-message">{errors.message.message}</p>}
              {!errors.message && <p className="placeholder-error-message">&nbsp;</p>}
            </div>
          </div>
          <input type="submit" id="submit" value="Send" />
        </form>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          message has been sent successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={opensec} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Failed to send the message!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContactForm;
