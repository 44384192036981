import React from "react";
import PropertyDetailsGallery from "../components/properties/property-details-gallery";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getPrivatePropertyDetailsFn } from "../context/endpoints";

export default function SalePropertyDetailsGallery() {
  const location = useLocation();
  const id = location.pathname.split("/")[4];

  // This code to fatch the property gallery by id
  const property = useQuery({
    queryKey: ["PropertyDetailsData"],
    queryFn: () => getPrivatePropertyDetailsFn(id),
  });
  return <PropertyDetailsGallery property={property} />;
}
