import React from "react";
import Header from "../components/Header";
import Testimonial from "../components/testimonial";
import Footer from "../components/footer";
import { useQuery } from "@tanstack/react-query";
import { getSettingsFn, getTeamFn } from "../context/endpoints";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { Helmet } from "react-helmet";
import ErrorMessage from "../components/loading-error/error";
import People from "../components/team/people";
import FeaturedPeople from "../components/team/featured-people";

const About = () => {
  // This code is using the useQuery hook from a library like React Query. It is fetching data using the getSettingsFn function and storing the result in the isPending, error, and data variables. The queryKey is used to identify the query.
  const websitesettingsData = useQuery({
    queryKey: ["settingsData"],
    queryFn: () => getSettingsFn(),
  });

  // This code is using the useQuery hook from a library like React Query. It is fetching data using the getTeamFn function and storing the result in the isPending, error, and data variables. The queryKey is used to identify the query.
  const team = useQuery({
    queryKey: ["TeamData"],
    queryFn: () => getTeamFn(),
  });

  // This code snippet creates an empty object called websitesettings, and if isPending is false and there is no error, it uses Object.assign to merge the contents of all the objects in the data array into the websitesettings object.
  let websitesettings = {};
  if (!websitesettingsData.isPending && !websitesettingsData.error) {
    websitesettings = Object.assign({}, ...websitesettingsData.data);
  }
  return (
    <>
      {websitesettingsData.isPending ? (
        <Header />
      ) : websitesettingsData.error ? (
        <ErrorMessage>{websitesettingsData.error.message}</ErrorMessage>
      ) : (
        <>
          <Helmet>
            <title>About us - BlackBrick</title>
            <meta name="description" content="Meet the team" />
            <meta name="keywords" content={websitesettings.keywords} />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content="https://blackbrickproperty.com/about"
            />
            <meta property="og:title" content="About us - BlackBrick" />
            <meta property="og:description" content="Meet the team" />
            <meta
              property="og:image"
              content={websitesettings.teampageheader}
            />
            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content="https://blackbrickproperty.com/about"
            />
            <meta property="twitter:title" content="About us - BlackBrick" />
            <meta property="twitter:description" content="Meet the team" />
            <meta
              property="twitter:image"
              content={websitesettings.teampageheader}
            />
          </Helmet>
          <Header />

          <div
            className="about-header-container"
            style={{
              backgroundImage: `url(${websitesettings.teampageheader})`,
            }}
          >
            <div className="about-header-titles">
              <h1>About us</h1>
              <h2>Meet the team</h2>
            </div>
          </div>
        </>
      )}
      <div className="container">
        <div className="about-container">
          <div className="about-texts">
            <h3>
              “We are determined to prove that we can achieve better results for
              our customers by leveraging the human connection and simply
              deliver our promise”.
            </h3>
            <p>
              Fuelled by an experienced team of professionals and innovators, we
              combine technology with human connections, optimising the customer
              journey across sales and marketing, maximising impact on both an
              emotional and commercial level.{" "}
              <span>
                Our philosophy is simple, we are individuals speaking to real
                people, we understand the importance of considered, and personal
                approaches to communication. We encourage open dialogue, and
                honest collaboration. From this we build a deep sense of
                partnership and trust with our customers.
              </span>
            </p>
          </div>
          <div className="about-people-container">
            <h4>People</h4>
            <div className="about-featured-people about-featured-people-first">
              {team.isPending ? null : team.error ? (
                <ErrorMessage>{team.error.message}</ErrorMessage>
              ) : (
                <>
                  {team.data
                    .filter(
                      (team) =>
                        team.feature === true && team.name === "Matthew J Bate"
                    )
                    .map((team, index) => (
                      <FeaturedPeople
                        index={index}
                        image={team.image}
                        name={team.name}
                        jobTitle={team.jobTitle}
                        description={team.description}
                        email={team.email}
                      />
                    ))}
                </>
              )}
            </div>
            <div className="about-featured-people">
              {team.isPending ? null : team.error ? (
                <ErrorMessage>{team.error.message}</ErrorMessage>
              ) : (
                <>
                  {team.data
                    .filter(
                      (team) =>
                        team.feature === true && team.name !== "Matthew J Bate"
                    )
                    .map((team, index) => (
                      <FeaturedPeople
                        index={index}
                        image={team.image}
                        name={team.name}
                        jobTitle={team.jobTitle}
                        description={team.description}
                        email={team.email}
                        AgentID = {team.AgentID}
                        isActive = {team.isActive}
                      />
                    ))}
                </>
              )}
            </div>
            <div className="about-people">
              <h4>Starting Lineup</h4>
              {team.isPending ? null : team.error ? (
                <ErrorMessage>{team.error.message}</ErrorMessage>
              ) : (
                <Swiper
                  slidesPerView={2}
                  spaceBetween={14}
                  breakpoints={{
                    576: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    992: {
                      slidesPerView: 4,
                    },
                    1200: {
                      slidesPerView: 5,
                    },
                    1400: {
                      slidesPerView: 5,
                    },
                  }}
                  navigation={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Navigation]}
                  className="mySwiper"
                >
                  {team.data
                    .filter((team) => team.feature === false)
                    .map((team, index) => (
                      <SwiperSlide key={index}>
                        <People
                          name={team.name}
                          image={team.image}
                          jobTitle={team.jobTitle}
                          email={team.email}
                          AgentID = {team.AgentID}
                          isActive = {team.isActive}

                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      </div>
      <Testimonial />
      <Footer />
    </>
  );
};

export default About;
