import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import { Autoplay, Navigation } from "swiper";

const ImagePreview = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const images = urlParams.get('images') || [];

    // Parse the images parameter to extract the image URLs
    let imageUrls = [];
    try {
        // Attempt to parse the images parameter to extract the image URLs
        imageUrls = JSON.parse(images);
    } catch (error) {
        console.error("Error parsing images parameter:", error);
    }

    return (
        <div className="image-preview">
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                grabCursor={true}
                loop={true}
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
            >
                {imageUrls.length > 0 &&
                    imageUrls.map((item, index) => (
                        <SwiperSlide key={index}>
                            <img
                                style={{ 
                                    width: '100%',
                                    height: 'auto',
                                    objectFit: 'contain', // Fit the entire image within the slide
                                    maxHeight: '100%', // Prevent the image from exceeding the slide height
                                }}
                                src={item}
                                alt={`slider image ${index}`}
                            />
                        </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    );
};

export default ImagePreview;
