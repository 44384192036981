import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/footer";
import { fetchMediaCoverages, cancelMediaCoverage } from "../context/endpoints"; // Adjust the import path accordingly
import "./mediaCoverageList.css";

const MediaCoverageList = () => {
    const [mediaCoverages, setMediaCoverages] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCoverages, setFilteredCoverages] = useState([]);
    const [password, setPassword] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const staticPassword = "bbp@1114"; // Static password for access
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        const getMediaCoverages = async () => {
            try {
                const coverages = await fetchMediaCoverages();
                setMediaCoverages(coverages);
            } catch (error) {
                console.error("Error fetching media coverages:", error);
            }
        };

        getMediaCoverages();
    }, []);

    useEffect(() => {
        const filtered = mediaCoverages.filter((coverage) =>
            coverage.advisor.toLowerCase().includes(searchTerm.toLowerCase()) ||
            coverage.photographer.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCoverages(filtered);
    }, [searchTerm, mediaCoverages]);

    const handleEdit = (coverage) => {
        navigate(`/media/coverage/${coverage._id}`, { state: { coverage } });
    };
    

    const handleCancel = async (id) => {
        try {
            const response = await cancelMediaCoverage(id);
            if (response) {
                setMediaCoverages((prevCoverages) =>
                    prevCoverages.map((coverage) =>
                        coverage._id === id ? { ...coverage, status: "Cancelled" } : coverage
                    )
                );
                setFilteredCoverages((prevCoverages) =>
                    prevCoverages.map((coverage) =>
                        coverage._id === id ? { ...coverage, status: "Cancelled" } : coverage
                    )
                );
            }
        } catch (error) {
            console.error("Error cancelling media coverage:", error);
        }
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === staticPassword) {
            setIsAuthenticated(true);
        } else {
            alert("Incorrect password, please try again.");
        }
    };

    return (
        <>
            <Helmet>
                <title>Media Coverage List</title>
            </Helmet>
            <Header />
            <div className="media-coverage-list-container">
                {!isAuthenticated ? (
                    <form onSubmit={handlePasswordSubmit} className="password-form">
                        <h2 className="mc-mainheading">Enter Password to Access</h2>
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button className = "search-person" type="submit">Submit</button>
                    </form>
                ) : (
                    <>
                        <h1>Media Coverage List</h1>
                        <input
                            type="text"
                            className="input-media"
                            placeholder="Search by Advisor & Ads"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <table>
                            <thead>
                                <tr>
                                    <th>Advisor</th>
                                    <th>Listing Status</th>
                                    <th>Photographer</th>
                                    <th>Date</th>
                                    <th>Time Slot</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredCoverages.map((coverage) => (
                                    <tr key={coverage._id}>
                                        <td>{coverage.advisor}</td>
                                        <td>{coverage.listingStatus}</td>
                                        <td>{coverage.photographer}</td>
                                        <td>{new Date(coverage.date).toLocaleDateString()}</td>
                                        <td>{coverage.timeSlot}</td>
                                        <td>{coverage.status}</td>
                                        <td>
                                            <button className="edit-button" onClick={() => handleEdit(coverage)}>Edit</button>
                                            {coverage.status !== "Cancelled" && (
                                                <button className="cancel-button" onClick={() => handleCancel(coverage._id)}>Cancel</button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
            <Footer />
        </>
    );
};

export default MediaCoverageList;
