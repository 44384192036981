import React from "react";
import PropertyDetails from "../components/properties/property-details";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  getSalePropertyDetailsFn,
  getSalesPropertiesFn,
} from "../context/endpoints";

export default function SalePropertyDetails() {
  const location = useLocation();
  const id = location.pathname.split("/")[4];

  // This code to fatch the property details by id
  const property = useQuery({
    queryKey: ["PropertyDetailsData"],
    queryFn: () => getSalePropertyDetailsFn(id),
  });

  // This code initializes an empty object called similarProperties. If the property is not pending and there is no error, it sets the similarProperties object with values for collection, location, bedrooms, minPrice, and maxPrice based on the property data.
  let similarProperties = {};
  if (!property.isPending && !property.error) {
    similarProperties = {
      collection: "",
      location: property.data.Community,
      bedrooms: property.data.Bedrooms,
      minPrice: "",
      maxPrice: "",
    };
  }

  // This code fetches similar properties based on the similarProperties object.
  const similarPropertiesFn = useQuery({
    queryKey: ["SalesPropertiesData", similarProperties],
    queryFn: () => getSalesPropertiesFn(similarProperties),
  });
  return (
    <PropertyDetails
      property={property}
      similarProperties={similarPropertiesFn}
    />
  );
}
