import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/LandingHeader";
import HeroSection from "../components/HeroSection";
import FormSection from "../components/FormComponent";
import styles from "./Home.module.css";
import ShowCase from "../components/Showcase";
import PropertySection from "../components/PropertySection";
import Register from "../components/RegisterLead";
import GoogleReviews from "../components/google-reviews";
import { Helmet } from "react-helmet";

export default function Home() {
  const aboutUsRef = useRef(null);
  const propertySectionRef = useRef(null);
  const reviewsRef = useRef(null);

  const location = useLocation();
 
  const registerRef = useRef(null); // Create a ref for the Register component

  // Function to scroll to the Register section
  const scrollToRegister = () => {
    registerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const path = location.pathname;

    console.log(path,"pathhhhhh")
    if (path === "/landing/page/aboutus") {
      aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (path === "/landing/page/properties") {
      propertySectionRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (path === "/landing/page/register") {
      registerRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (path === "/landing/page/reviews") {
      reviewsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <Helmet>
            <title>Landing Page - BlackBrick</title>
            <meta name="keywords" content={`A Curated Collection of Dubai’s Finest Luxury Properties by BlackBrick
                Discover Your Dream Luxury Home in Dubai’s Premiem Communities
                Explore BlackBrick’s exclusive range of Luxury Apartments for Sale in Dubai, modern villas, and
                Dubai Luxury Penthouses for Sale. Our properties feature breathtaking views, contemporary design,
                and premium amenities. Whether you&#39;re seeking a serene family home or a private retreat, we’ll
                help you Buy a Luxury Apartment in Dubai or find the Best Villas in Dubai for Sale.

                About Us:
                At BlackBrick, we believe that real estate is more than just transactions; it’s about creating
                meaningful connections and delivering on promises. We specialize in Luxury Real Estate in Dubai,
                offering high-end options like Luxury Homes for Sale in Dubai and Modern Villas for Sale in Dubai.
                Our journey began with the belief that real estate should be as personal as a handshake. In Dubai&#39;s
                competitive market, BlackBrick stands apart as a partner, not just a broker. We offer a curated
                selection of Luxury Property Real Estate in Dubai, ensuring every client feels valued throughout
                their property journey. At BlackBrick, we’re not just selling properties—we’re building communities.`} />
      </Helmet>

      <div className={styles.landingPage}>
        <Header theme="dark" />
        <HeroSection />
        <FormSection />
      </div>

      <Header theme="light" />

      <div className={styles.banner}>
        <ShowCase />
      </div>

      {/* PropertySection */}
      <div  ref={propertySectionRef}>
        <PropertySection onRegisterClick = {scrollToRegister} />
      </div>

      {/* About Us Section */}
      <div
        className={styles.landingPageAbout}
        style={{
          backgroundImage: `url(https://blackbrickwebsite.s3.me-central-1.amazonaws.com/DSC07279.JPG)`,
        }}
        ref={aboutUsRef} // Add ref for About Us section
      >
        <div className={styles.aboutHeaderTiles}>
          <h1>About Us</h1>
        </div>
      </div>

      <div className="container">
        <div className="about-container">
          <div className={styles.aboutText}>
            <h3>
              “We are determined to prove that we can achieve better results for
              our customers by leveraging the human connection and simply
              deliver our promise.”
            </h3>
            <p>
              <span>
              At BlackBrick, we believe that Real Estate is more than just transactions; it’s about creating
              meaningful connections and delivering on promises. We specialize in Luxury Real Estate in Dubai,
              offering high-end options like Luxury Homes for Sale in Dubai and Modern Villas for Sale in Dubai.
              </span>
              Our journey began with the belief that Real Estate should be as personal as a handshake. In Dubai'ss
              competitive market, BlackBrick stands apart as a partner, not just a broker. We offer a curated
              selection of Luxury Property Real Estate in Dubai, ensuring every client feels valued throughout
              their property journey. At BlackBrick, we’re not just selling properties—we’re building communities.
            </p>
          </div>
        </div>
      </div>

      {/* Reviews Section */}
      <div ref={reviewsRef}>
        <GoogleReviews
          placeId={"ChIJ8SZPNC5rXz4RAGkOeFRCUGo"}
          apiKey={"AIzaSyCr8gUuxHjGaun4OPFzy6xyXGYVXAKfDBI"}
        />
      </div>

      {/* Register Section */}
      <div ref={registerRef}>
        <Register />
      </div>
    </>
  );
}
