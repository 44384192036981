import React, { useEffect } from "react";
import PropertyCard from "../property-card";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import ErrorMessage from "../loading-error/error";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { useQuery } from "@tanstack/react-query";
import { getRentalsFeaturedPropertiesFn, getRentalsPropertiesFn } from "../../context/endpoints";

const FeaturedListingsForRent = ({ Agent, AgentID }) => {
  const { isPending, error, data, refetch } = useQuery({
    queryKey: AgentID ? ["getRentalsPropertiesFn", AgentID] : ["getRentalsPropertiesFn"],
    queryFn: () => {
      return AgentID ? getRentalsPropertiesFn({ AgentID }) : getRentalsFeaturedPropertiesFn();
    },
  });



  useEffect(() => {
    // Refetch data when AgentID changes
    refetch();
  }, [AgentID]);

  if (!data || data.properties.length === 0) {
    return null;
  }

  return (
    <div className="featured-listings-for-rent">
      <div className="container">
        <h5 className="home-listings-title">{Agent ? `${Agent} listings for rent` : "featured listings for rent"}</h5>
        {isPending ? (
          <div className="properties-listings">
            {Array.from({ length: 3 }).map((_, index) => (
              <Box key={index}>
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: "259px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "65px", marginBottom: "-9px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px" }} />
                </Skeleton>
              </Box>
            ))}
          </div>
        ) : error ? (
          <ErrorMessage>{error.message}</ErrorMessage>
        ) : (
          <div className="home-listings">
            <div className="property-cards-container">
              <Swiper
                slidesPerView={1}
                spaceBetween={18}
                grabCursor={true}
                loop={data.properties > 3 ?true : false}
                breakpoints={{
                  576: { slidesPerView: 1 },
                  768: { slidesPerView: 2 },
                  992: { slidesPerView: 2 },
                  1200: { slidesPerView: 3 },
                  1400: { slidesPerView: 3 },
                }}
                navigation={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
              >
                {data.properties.map((property, index) => (
                  <SwiperSlide key={property.RefNo}>
                    <PropertyCard
                      cardLink={`/properties/rent/property/${property.RefNo}`}
                      cardImage={property.Images?.Image}
                      cardTitle={property.MarketingTitle}
                      cardLocation={property.Community}
                      cardBedrooms={property.Bedrooms}
                      cardPrice={property.Rent}
                      priceOnApplication={property.PriceOnApplication}
                      RefNo={property.RefNo}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}
        <div className="home-listings-button">
          <Link to={AgentID ? `/properties/rent?AgentID=${AgentID}&AgentName=${Agent}` : "/properties/rent"}>
            <button>view all rentals</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FeaturedListingsForRent;
