import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation for route detection
import Header from "../components/Header";
import ExclusiveSlider from "../components/home/exclusive-slider";
import HomeText from "../components/home/home-text";
import FeaturedListingsForSale from "../components/home/featured-listings-for-sale";
import FeaturedListingsForRent from "../components/home/featured-listings-for-rent";
import Collections from "../components/home/collections";
import BuildSection from "../components/build-section";
import HomeTeam from "../components/home/home-team";
import Testimonial from "../components/testimonial";
import Footer from "../components/footer";
import RegisterForm from "../components/Register";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const location = useLocation(); // Hook to detect the current route

  useEffect(() => {
    const hasShownPopup = sessionStorage.getItem('hasShownPopup');
    const hasFormSubmitted = sessionStorage.getItem('hasFormSubmitted');

    // Check if popup has not been shown and form has not been submitted
    // if (!hasShownPopup && !hasFormSubmitted) {
    //   const timer = setTimeout(() => {
    //     setShowPopup(true);
    //     sessionStorage.setItem('hasShownPopup', 'true');
    //   }, 3000); // Delay of 2 seconds

    //   return () => clearTimeout(timer);
    // }
  }, []);

  const handleEnquireClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSubmitStatus = (success, result) => {
    if (success) {
      // setHasSubmitted(true);
      sessionStorage.setItem('hasFormSubmitted', 'true');
      // setShowPopup(false); // Hide the popup after submission
    } else {
      toast.error('Failed to submit enquiry. Please try again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log('Form submission failed.');
    }
  };

  return (
    <>
      <Header />
      <ExclusiveSlider />
      <FeaturedListingsForSale />
      <FeaturedListingsForRent />
      <HomeText onEnquireClick={handleEnquireClick} />
      {showPopup && !hasSubmitted && (
        <RegisterForm onClose={handleClosePopup} onSubmitStatus={handleSubmitStatus} />
      )}
    
      <Collections />
      {/* <BuildSection /> */}
      <HomeTeam />
      <Testimonial />
      <Footer />
      <ToastContainer />
    </>
  );
};

export default Home;
