import React from "react";
import Header from "../components/Header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 Page not found.</title>
        <meta name="description" content="404 Page not found." />
        <meta property="og:title" content="404 Page not found." />
        <meta property="og:description" content="404 Page not found." />
        <meta property="og:image" content="/logo512.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="404 Page not found." />
        <meta property="twitter:description" content="404 Page not found." />
        <meta property="twitter:image" content="/logo512.png" />
      </Helmet>
      <Header />
      <div class="not-found-container">
        <div class="container">
          <span role="img" aria-label="emoji">
            🙁
          </span>
          <h1>404</h1>
          <h2>Page not found.</h2>
          <p>
            Sorry, we couldn't find the page you where looking for. We suggest
            that you return to home page.
          </p>
          <button>Go to Homepage</button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
