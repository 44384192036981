import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import { saveValuationLead } from "../context/endpoints";
import "./ListPropertyForm.css"; // Import the CSS

const ListPropertyForm = () => {
  const [formData, setFormData] = useState({
    location: "",
    additionalInfo: "",
    firstName: "",
    lastName: "",
    contactPhone: "",
    contactEmail: "",
    bedrooms: "", // Added field for number of bedrooms
  });

  const [loading, setLoading] = useState(false); // Track loading state
  const [submitted, setSubmitted] = useState(false); // Track submission success

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true
    setSubmitted(false); // Reset submitted state on new submission
    try {
      const result = await saveValuationLead(formData);
      console.log('Success:', result);
      setSubmitted(true); // Set submitted state to true
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Reset loading state after processing
    }
  };

  return (
    <>
      <Helmet>
        <title>Register Your Property</title>
      </Helmet>
      <Header />
      <div className="form-container">
        {!submitted ? ( // Conditional rendering based on submission state
          <>
            <h1 className="form-title">Request your free valuation</h1>
            <h3 className="form-subtitle">After something else? Please add your inquiry in the “Additional information” box.</h3>
            <form onSubmit={handleSubmit} className="property-form">
              {/* Common Location Input */}
              <div className="form-group">
                <label htmlFor="location" className="label-location">Location*</label>
                <input
                  type="text"
                  name="location"
                  id="location"
                  value={formData.location}
                  onChange={handleChange}
                  className="listing-form-input"
                  required
                />
              </div>

              {/* Number of Bedrooms Input */}
              <div className="form-group">
                <label htmlFor="bedrooms" className="label-bedrooms">Number of Bedrooms*</label>
                <input
                  type="number"
                  name="bedrooms"
                  id="bedrooms"
                  value={formData.bedrooms}
                  onChange={handleChange}
                  className="listing-form-input"
                  min="0"
                  required
                />
              </div>

              {/* Common Additional Information Input */}
              <div className="form-group">
                <label htmlFor="additionalInfo" className="label-additional-info">Additional Information (optional)</label>
                <textarea
                  name="additionalInfo"
                  id="additionalInfo"
                  value={formData.additionalInfo}
                  onChange={handleChange}
                  className="form-textarea"
                ></textarea>
              </div>

              <h2 className="contact-title">Your Details</h2>

              <div className="form-group">
                <label htmlFor="firstName" className="label-first-name">First Name*</label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="listing-form-input"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="lastName" className="label-last-name">Last Name*</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="listing-form-input"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="contactPhone" className="label-contact-phone">Phone Number*</label>
                <input
                  type="tel"
                  name="contactPhone"
                  id="contactPhone"
                  value={formData.contactPhone}
                  onChange={handleChange}
                  className="listing-form-input"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="contactEmail" className="label-contact-email">Email (optional)</label>
                <input
                  type="email"
                  name="contactEmail"
                  id="contactEmail"
                  value={formData.contactEmail}
                  onChange={handleChange}
                  className="listing-form-input"
                />
              </div>

              <button type="submit" className="form-submit-btn" disabled={loading}>
                {loading ? "Submitting..." : "Submit Valuation"}
              </button>
            </form>
          </>
        ) : (
          <div className="thank-you-container">
            <p className="thank-you-message">Thank you for your submission!<br></br> One of our Advisors will contact you shortly</p>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ListPropertyForm;
