import React, { useState } from "react";
import styles from "./PropertyForm.module.css";
import PhoneInputField from "../PhoneInput"; // Importing the updated PhoneInputField
import { userEnquiry } from "./../../context/endpoints"; // Importing the API method

const PropertyForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    minBudget: "",
    maxBudget: "",
    userType: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for managing button status

  const handleChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable button on submit

    try {
      const currentURL = window?.location?.href;

      // Call the userEnquiry API
      const data = await userEnquiry(
        formData.fullName,
        formData.email,
        formData.phone,
        `Min Budget: ${formData.minBudget}, Max Budget: ${formData.maxBudget}, User Type: ${formData.userType}`,
        currentURL
      );

      if (data.statusCode === 200) {
        setIsSubmitted(true);
        // Handle tracking events (e.g., Meta Pixel or Google Analytics)
        if (window.fbq) {
          window.fbq("track", "Lead");
        }
        if (window.gtag) {
          window.gtag("event", "lead", { send_to: "G-F4J8CJGY6Y" });
        }
      } else {
        console.error("Submission failed:", data);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsSubmitting(false); // Re-enable button after response
    }
  };

  return (
    <div className={styles.formBackground}>
      <div className={styles.formContainer}>
        {isSubmitted ? (
          <div className="success-message">
            <h3>Thank you for your submission! </h3>
            <p>Rest assured, we’ll take it from here. One of our Property Advisors will be in touch shortly to assist you further.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className={styles.formContent}>
            <div className={styles.mainFields}>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={(e) => handleChange(e.target.value, "fullName")}
                placeholder="Full Name"
                required
              />

              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => handleChange(e.target.value, "email")}
                placeholder="Email Address"
                required
              />

              <PhoneInputField
                value={formData.phone}
                onChange={(value) => handleChange(value, "phone")}
                isPropertyField
              />
            </div>

            {/* Budget Section */}
            <div className={styles.budgetSection}>
              <h5>Budget</h5>
              <div className={styles.budgetFields}>
                <input
                  type="number"
                  id="minBudget"
                  name="minBudget"
                  value={formData.minBudget}
                  onChange={(e) => handleChange(e.target.value, "minBudget")}
                  placeholder="Min Budget"
                  required
                />
                <input
                  type="number"
                  id="maxBudget"
                  name="maxBudget"
                  value={formData.maxBudget}
                  onChange={(e) => handleChange(e.target.value, "maxBudget")}
                  placeholder="Max Budget"
                  required
                />
              </div>
            </div>

            {/* User Type Section */}
            <div className={styles.radioSection}>
              <h5>I'M A</h5>
              <div className={styles.radioGroup}>
                <div className={styles.radioItemWrapper}>
                  <label className={styles.radioItem}>
                    <input
                      type="radio"
                      name="userType"
                      value="broker"
                      onChange={(e) => handleChange(e.target.value, "userType")}
                    />
                    <span>Broker</span>
                  </label>
                  <label className={styles.radioItem}>
                    <input
                      type="radio"
                      name="userType"
                      value="investor"
                      onChange={(e) => handleChange(e.target.value, "userType")}
                    />
                    <span>Investor</span>
                  </label>
                </div>
                <label className={styles.radioItem}>
                  <input
                    type="radio"
                    name="userType"
                    value="end user"
                    onChange={(e) => handleChange(e.target.value, "userType")}
                  />
                  <span>End User</span>
                </label>
              </div>
            </div>

            <p className={styles.disclaimer}>
              I agree to share my data with BlackBrick Property, and allow BlackBrick Property to collect, control or process my data in order to communicate with me. Should I wish to unsubscribe, I will send an email to be@blackbrickproperty.com
            </p>
            <button
              type="submit"
              className={`${styles.submitButton} submit-contactus`}
              disabled={isSubmitting} // Disable button while submitting
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default PropertyForm;
