import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";

const BlogCard = ({ article }) => {

  return (
    <div className="journal-article-card">
      <Link to={`/blog/${article.title}`}>
        <img src={article.image ? article?.image[0] : article.bannerImageUrl} alt="article images" />
        <div>
          <h4>{article.title}</h4>
          <h5>{Moment(article.created).format("DD.MM.YY")}</h5>
        </div>
      </Link>
    </div>
  );
};

export default BlogCard;
