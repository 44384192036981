import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getSettingsFn, getArticleFn } from "../context/endpoints";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import ErrorMessage from "../components/loading-error/error";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import DescriptionReadMore from "../components/description-read-more";

const ArticleDetails = () => {
  const { id } = useParams();

  // This code is using the useQuery hook from a library like React Query. It is fetching data using the getSettingsFn function and storing the result in the isPending, error, and data variables. The queryKey is used to identify the query.
  const websitesettingsData = useQuery({
    queryKey: ["settingsData"],
    queryFn: () => getSettingsFn(),
  });

  // This code to fetch the article data by id
  const article = useQuery({
    queryKey: ["ArticleData", id],
    queryFn: () => getArticleFn(id),
  });

  // This code snippet creates an empty object called websitesettings, and if isPending is false and there is no error, it uses Object.assign to merge the contents of all the objects in the data array into the websitesettings object.
  let websitesettings = {};
  if (!websitesettingsData.isPending && !websitesettingsData.error) {
    websitesettings = Object.assign({}, ...websitesettingsData.data);
  }
  return (
    <>
      <Header />
      <div className="backtoallresults">
        <Link to={`/journal`}>
          <h2>
            <span>&#60;</span>back to all results
          </h2>
        </Link>
      </div>
      {websitesettingsData.isPending || article.isPending ? (
        <div className="container-secondary">
          <div className="journal-details-container">
            <div className="details-container">
              <div className="details-left-side">
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "65px", marginBottom: "5px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px", marginBottom: "30px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px", marginBottom: "25px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px" }} />
                </Skeleton>
              </div>
              <div className="details-right-side">
                <div className="details-right-side-main-image">
                  <Skeleton variant="rectangular" width="100%">
                    <div style={{ paddingTop: "597px" }} />
                  </Skeleton>
                </div>
                <div
                  className="details-description-container"
                  style={{ display: "block" }}
                >
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px" }} />
                  </Skeleton>
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px" }} />
                  </Skeleton>
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px" }} />
                  </Skeleton>
                </div>
                <div className="details-right-side-images">
                  {Array.from({ length: 2 }).map((_, index) => (
                    <Box key={index}>
                      <Skeleton variant="rectangular" width="100%">
                        <div style={{ paddingTop: "313px" }} />
                      </Skeleton>
                    </Box>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : websitesettingsData.error || article.error ? (
        <ErrorMessage>{article.error.message}</ErrorMessage>
      ) : (
        <>
          <Helmet>
            <title>{`${article.data.title || ""} - BlackBrick`}</title>
            <meta name="description" content={article.data.short_description} />
            <meta name="keywords" content={article.data.keyword} />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`https://blackbrickproperty.com/journal/${article.data._id}`}
            />
            <meta
              property="og:title"
              content={`${article.data.title} - BlackBrick`}
            />
            <meta
              property="og:description"
              content={article.data.short_description}
            />
            <meta property="og:image" content={article.data.image[0]} />
            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content={`https://blackbrickproperty.com/journal/${article.data._id}`}
            />
            <meta
              property="twitter:title"
              content={`${article.data.title} - BlackBrick`}
            />
            <meta
              property="twitter:description"
              content={article.data.short_description}
            />
            <meta property="twitter:image" content={article.data.image[0]} />
          </Helmet>
          <div className="container-secondary">
            <div className="journal-details-container">
              <div className="details-container">
                <div className="details-left-side">
                  <h1>{article.data.title}</h1>
                  <p>{article.data.short_description}</p>
                  <h2>DETAILS:</h2>
                  <h4>{websitesettings.email}</h4>
                  <h4>Zabeel House, Unit 1114, Onyx Tower 2, Dubai, UAE</h4>
                  <h5>{websitesettings.phone}</h5>
                </div>
                <div className="details-right-side">
                  <div className="details-right-side-main-image">
                    {article.data.image.length > 0 &&
                      article.data.image
                        .slice(0, 1)
                        .map((item, index) => (
                          <img key={index} src={item} alt="journal images" />
                        ))}
                    {article.data.image.length > 1 ? (
                      <Link to={`/journal/${article.data._id}/gallery`}>
                        <button>more images</button>
                      </Link>
                    ) : null}
                  </div>
                  <div className="tags-container">
                    {article.data.topics.length > 0 &&
                      article.data.topics.map((tag, index) => (
                        <Link key={index} to={`/journal?topic=${tag}`}>
                          <h4>{tag}</h4>
                        </Link>
                      ))}
                  </div>
                  <div className="details-description-container">
                    <div className="details-description">
                      <DescriptionReadMore>
                        {article.data.full_description}
                      </DescriptionReadMore>
                    </div>
                  </div>
                  <div className="details-right-side-images">
                    {article.data.image.length > 1 &&
                      article.data.image
                        .slice(1, 4)
                        .map((item, index) => (
                          <img key={index} src={item} alt="journal images" />
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default ArticleDetails;
