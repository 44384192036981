import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query"; // Adjusted for v5
import {
  getSalesCommunitiesFn,
  getRentalsCommunitiesFn
} from "../../context/endpoints";

// Determine which query to use based on listing type
const fetchCommunities = (listingType) => {
  return listingType === "Buy" 
    ? getSalesCommunitiesFn() 
    : getRentalsCommunitiesFn();
};
const generatePriceOptions = (start, end) => {
  const options = [];
  let step = 100000; // Initial step
  let current = start;

  while (current <= end) {
    options.push(current);
    // Adjust step based on the current value
    if (current < 1000000) {
      current += step; // Step of 100,000 below 1 million
    } else if (current < 5000000) {
      step = 500000; // Step of 500,000 between 1 million and 5 million
      current += step;
    } else {
      step = 1000000; // Step of 1,000,000 above 5 million
      current += step;
    }
  }
  
  return options;
};
function HomeFilter({
  listingType,
  setListingType,
  propertyType,
  setPropertyType,
  community,
  setCommunity,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  minBedrooms,
  setMinBedrooms,
  maxBedrooms,
  setMaxBedrooms,
  onSearch,
}) {
  const bedroomOptions = Array.from({ length: 10 }, (_, i) => i + 1); // Array from 1 to 10 for bedroom options

  // Fetch communities based on listing type
  const { data: communitiesData = {}, status: communitiesStatus } = useQuery({
    queryKey: [`${listingType}CommunitiesData`],
    queryFn: () => fetchCommunities(listingType),
    enabled: !!listingType, // Only fetch if listingType is available
  });

  // Get communities from the fetched data
  const communityOptions = communitiesStatus === "success" 
    ? communitiesData.communities || [] 
    : [];

  // Generate price options (e.g., from 100,000 to 50,000,000 with a step of 100,000)
  const minPriceOptions = generatePriceOptions(100000, 50000000, 100000);
  const maxPriceOptions = generatePriceOptions(100000, 50000000, 100000);

  return (
    <div className="filters-bottom-overlay">
      <h3 className="filter-heading">Find Your Property With BlackBrick</h3>

      <select
        value={listingType}
        onChange={(e) => setListingType(e.target.value)}
        className="filter-select"
      >
        <option value="" disabled>Select Listing Type</option>
        {["Buy", "Rent"].map((type) => (
          <option key={type} value={type}>
            {type?.toUpperCase()}
          </option>
        ))}
      </select>

      <select
        value={propertyType}
        onChange={(e) => setPropertyType(e.target.value)}
        className="filter-select"
      >
        <option value="" disabled>PROPERTY TYPE</option>
        {["Apartment", "Villa", "Townhouse"].map((type) => (
          <option key={type} value={type}>
            {type?.toUpperCase()}
          </option>
        ))}
      </select>

      <select
        value={community}
        onChange={(e) => setCommunity(e.target.value)}
        className="filter-select filter-input-community"
      >
        <option value="" disabled>COMMUNITY</option>
        {communityOptions.map(name => (
          <option key={name} value={name}>
            {name?.toUpperCase()} 
          </option>
        ))}
      </select>

      <div className="filter-price-range">
        <select
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
          className="filter-select"
        >
          <option value="" disabled>MIN PRICE</option>
          {minPriceOptions.map(price => (
            <option key={price} value={price}>
              {price.toLocaleString()} AED
            </option>
          ))}
        </select>

        <select
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
          className="filter-select"
        >
          <option value="" disabled>MAX PRICE</option>
          {maxPriceOptions.map(price => (
            <option key={price} value={price}>
              {price.toLocaleString()} AED
            </option>
          ))}
        </select>
      </div>

      <div className="filter-bedrooms-range">
        {[{ label: "MIN BED", value: minBedrooms, setter: setMinBedrooms }, { label: "MAX BED", value: maxBedrooms, setter: setMaxBedrooms }].map(({ label, value, setter }) => (
          <select
            key={label}
            value={value}
            onChange={(e) => setter(e.target.value)}
            className="filter-select small-select"
          >
            <option value="" disabled>{label}</option>
            {bedroomOptions.map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        ))}
      </div>

      <button className="search-button" onClick={onSearch}>
        🔍
      </button>
    </div>
  );
}

HomeFilter.propTypes = {
  listingType: PropTypes.string.isRequired,
  setListingType: PropTypes.func.isRequired,
  propertyType: PropTypes.string.isRequired,
  setPropertyType: PropTypes.func.isRequired,
  community: PropTypes.string.isRequired, 
  setCommunity: PropTypes.func.isRequired, 
  minPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setMinPrice: PropTypes.func.isRequired,
  maxPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setMaxPrice: PropTypes.func.isRequired,
  minBedrooms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setMinBedrooms: PropTypes.func.isRequired,
  maxBedrooms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setMaxBedrooms: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default HomeFilter;
