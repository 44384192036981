import React from "react";
import PropertyGrid from "../PropertyGrid";
import styles from "./PropertySection.module.css"

export default function PropertySection({ onRegisterClick }) {
  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <h1>Discover Your Dream Luxury Home in Dubai’s Premiem Communities</h1>
        <h2>Explore BlackBrick’s exclusive range of Luxury Apartments for Sale in Dubai, modern villas, and
Dubai Luxury Penthouses for Sale. Our properties feature breathtaking views, contemporary design,
and premium amenities. Whether you're seeking a serene family home or a private retreat, we’ll
help you Buy a Luxury Apartment in Dubai or find the Best Villas in Dubai for Sale.</h2>

      </div>
      <PropertyGrid  onClickRegister = {onRegisterClick} />
    </div>  
  );
}
