import React from "react";
import PropertyCard from "../PropertyCard";
import styles from "./PropertyGrid.module.css";
import { useQuery } from "@tanstack/react-query"; // Importing useQuery
import { getLandingPagePropertiesFn } from "../../context/endpoints"; // Adjust the import path based on your project structure

const PropertyGrid = ({ onClickRegister }) => {
  // Define reference IDs
  const referenceIds = ["VI9085", "VI7570", "PH9077", "VI9239"];

  // Fetch properties using the reference IDs
  const { data, isLoading, error } = useQuery({
    queryKey: ["LandingPageProperties", referenceIds],
    queryFn: () => getLandingPagePropertiesFn(referenceIds),
  });


  // Handle loading and error states
  if (isLoading) {
    return <div style={{textAlign:"center"}}>Loading properties...</div>;
  }

  if (error) {
    return <div style={{textAlign:"center"}}>Error loading properties: {error.message}</div>;
  }


  const properties = data?.properties.map(x=>({
    image : x.Images?.Image[0].ImageURL,
    title: x.MarketingTitle,
    bedrooms : x.Bedrooms,
    location :x.Community,
    images : x.Images?.Image?.map(x=>x.ImageURL)
  }))  

 
  return (
    <div className={styles.mainLayout}>
      <div className={styles.grid}>
        {properties?.map((property, index) => (
          <PropertyCard
            images = {property.images} 
            key={index}
            image={property.image} // Adjust based on your fetched property structure
            title={property.title} // Adjust based on your fetched property structure
            bedrooms={property.bedrooms} // Adjust based on your fetched property structure
            location={property.location} // Adjust based on your fetched property structure
          />
        ))}
      </div>
      <div className={styles.learnMore}>
        <h2></h2>
        <button onClick={onClickRegister}>Register Your Interest</button>
      </div>
    </div>
  );
};

export default PropertyGrid;

