import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getArticleFn } from "../context/endpoints";
import Header from "../components/Header";
import Footer from "../components/footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Link } from "react-router-dom";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { Helmet } from "react-helmet";
import ErrorMessage from "../components/loading-error/error";

const PropertyDetailsGallery = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isGrid, setIsGrid] = useState(true);
  const [isSlider, setIsSlider] = useState(false);

  // This code is using the useQuery hook from a library like React Query. It is fetching data using the getArticleFn function and storing the result in the isPending, error, and data variables. The queryKey is used to identify the query.
  const { isPending, error, data } = useQuery({
    queryKey: ["ArticleData", id],
    queryFn: () => getArticleFn(id),
  });

  // This code to convert the gallery to grid view
  const clickIsGrid = () => {
    setIsGrid((current) => !current);
    setIsSlider((current) => !current);
  };

  // This code to convert the gallery to slider view
  const clickIsSlider = () => {
    setIsGrid((current) => !current);
    setIsSlider((current) => !current);
  };
  return (
    <>
      <Header />
      {isPending ? null : error ? (
        <ErrorMessage>{error.message}</ErrorMessage>
      ) : (
        <>
          <Helmet>
            <title>{`${data.title} Gallery's - BlackBrick`}</title>
            <meta name="description" content={`${data.title} Gallery's`} />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`https://blackbrickproperty.com/journal/${data._id}`}
            />
            <meta
              property="og:title"
              content={`${data.title} Gallery's - BlackBrick`}
            />
            <meta
              property="og:description"
              content={`${data.title} Gallery's`}
            />
            <meta property="og:image" content={data.image[0]} />
            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content={`https://blackbrickproperty.com/journal/${data._id}`}
            />
            <meta
              property="twitter:title"
              content={`${data.title} Gallery's - BlackBrick`}
            />
            <meta
              property="twitter:description"
              content={`${data.title} Gallery's`}
            />
            <meta property="twitter:image" content={data.image[0]} />
          </Helmet>
          <div className="backtoallresults">
            <Link to={`/journal/${data._id}`}>
              <h2>
                <span>&#60;</span>back to listing
              </h2>
            </Link>
            <Link to={`/journal/${data._id}`}>
              <h3>X</h3>
            </Link>
          </div>
          <div className="gallery-container">
            <div className="container">
              <div className="gallery-switch">
                <div
                  className={
                    isSlider
                      ? "gallery-switch-slider gallery-isGrid"
                      : "gallery-switch-slider"
                  }
                >
                  <h3 onClick={clickIsSlider}>Slider view</h3>
                </div>
                <div
                  className={
                    isGrid
                      ? "gallery-switch-grid gallery-isSlider"
                      : "gallery-switch-grid"
                  }
                >
                  <h3 onClick={clickIsGrid}>Grid view</h3>
                </div>
              </div>
              <Gallery>
                <div
                  className={
                    isSlider
                      ? "gallery-grid-container gallery-isGrid"
                      : "gallery-grid-container"
                  }
                >
                  {data.image.length > 0 &&
                    data.image.map((item, index) => (
                      <Item
                        key={index}
                        original={item}
                        thumbnail={item}
                        width="100%"
                        height="100%"
                      >
                        {({ ref, open }) => (
                          <img
                            ref={ref}
                            onClick={open}
                            src={item}
                            alt="property images"
                          />
                        )}
                      </Item>
                    ))}
                </div>
              </Gallery>
              <div
                className={
                  isGrid
                    ? "gallery-slider-container gallery-isSlider"
                    : "gallery-slider-container"
                }
              >
                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  spaceBetween={12}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {data.image.length > 0 &&
                    data.image.map((item, index) => (
                      <SwiperSlide key={index}>
                        <img src={item} alt="property images" />
                      </SwiperSlide>
                    ))}
                </Swiper>
                <Swiper
                  onSwiper={setThumbsSwiper}
                  spaceBetween={12}
                  slidesPerView={6}
                  freeMode={true}
                  watchSlidesProgress={true}
                  breakpoints={{
                    576: {
                      slidesPerView: 6,
                    },
                    768: {
                      slidesPerView: 10,
                    },
                    992: {
                      slidesPerView: 10,
                    },
                    1200: {
                      slidesPerView: 12,
                    },
                    1400: {
                      slidesPerView: 12,
                    },
                  }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {data.image.length > 0 &&
                    data.image.map((item, index) => (
                      <SwiperSlide key={index}>
                        <img src={item} alt="property images" />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default PropertyDetailsGallery;
