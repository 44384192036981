import React, { useState, useEffect } from "react";
import { NavLink,useNavigate } from "react-router-dom";
import RegisterForm from "./Register"; // Ensure the correct path to your RegisterForm component
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [propertiesDropdownOpen, setPropertiesDropdownOpen] = useState(false);
  const [moreDropdownOpen, setMoreDropdownOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMobile(prevIsMobile => !prevIsMobile);
  };

  const toggleDropdown = (event, dropdown, setDropdownState) => {
    event.stopPropagation();
    if (dropdown === "properties" && moreDropdownOpen) {
      setMoreDropdownOpen(false);
    } else if (dropdown === "more" && propertiesDropdownOpen) {
      setPropertiesDropdownOpen(false);
    }
    setDropdownState(prevState => !prevState);
    setActiveDropdown(prevState => (prevState === dropdown ? null : dropdown));
  };

  const closeMenu = event => {
    event.stopPropagation();
    setIsMobile(false);
    setActiveDropdown(null);
  };

  const closeDropdown = dropdown => {
    setActiveDropdown(prevState => (prevState === dropdown ? null : prevState));
  };



  const handleEnquireClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSubmitStatus = (success, result) => {
    if (success) {
      // Handle success
    } else {
      toast.error('Failed to submit enquiry. Please try again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log('Form submission failed.');
    }
  };

  return (
    <header className={scrolled ? "scrolled" : ""}>
      <NavLink to="/">
        <img className="header-img" alt="logo" />
      </NavLink>
      <nav className={isMobile ? "menuisMobile" : ""} onClick={toggleMenu}>
        <div className="menuisMobile-header">
          <NavLink className="menu-close-button" onClick={closeMenu}>
            X
          </NavLink>
          <NavLink className={`menu-logo${activeDropdown && activeDropdown !== "properties" && activeDropdown !== "more" ? " active" : ""}`} to="/">
            <img className="header-img" alt="logo" />
          </NavLink>
        </div>
        <ul className="header-menu-items">
          <li>
            <NavLink to="/properties/buy" activeClassName={activeDropdown === "properties" ? "active" : ""} onClick={() => closeDropdown("properties")}>Buy</NavLink>
          </li>
          <li>
            <NavLink to="/properties/rent" activeClassName={activeDropdown === "properties" ? "active" : ""} onClick={() => closeDropdown("properties")}>Rent</NavLink>
          </li>
          <li>
            <NavLink to="/private/office" activeClassName="active">
              Private Office
            </NavLink>
          </li>
        
          <li>
            <NavLink to="/bond" activeClassName="active header-last-child-a">
              The Bond
            </NavLink>
          </li>
          {/* <li>
          <NavLink to="/">
            <img className="header-img" alt="logo" />
          </NavLink>
          </li> */}
          <li>
            <a
              href="#"
              onClick={(event) => toggleDropdown(event, "more", setMoreDropdownOpen)}
              style={{ textDecoration: "none" }}
            >
              More <i className={`fa fa-chevron-${moreDropdownOpen ? "up" : "down"}`}></i>
            </a>
            <ul
              className={`dropdown ${moreDropdownOpen ? "show" : ""}`}
              onClick={(event) => event.stopPropagation()}
            >
              <li>
                <NavLink to="/design" activeClassName="active" onClick={() => setMoreDropdownOpen(false)}>Design</NavLink>
              </li>
              <li>
                <NavLink to="/advisory" activeClassName="active" onClick={() => setMoreDropdownOpen(false)}>Advisory</NavLink>
              </li>

              {/* <li>
                <NavLink to="/blogs" activeClassName="active" onClick={() => setMoreDropdownOpen(false)}>Blogs</NavLink>
              </li> */}
              <li>
                <NavLink to="/about" activeClassName="active" onClick={() => setMoreDropdownOpen(false)}>About</NavLink>
              </li>
              <li>
                <NavLink to="/contact" activeClassName="active" onClick={() => setMoreDropdownOpen(false)}>Contact</NavLink>
              </li>
            </ul>
          </li>
         

      
         
        </ul>
        <div className="menuisMobile-footer">
          <a
            href="https://www.facebook.com/BlackBrick.ae"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa-brands fa-facebook-f"></i>
          </a>
          <a
            href="https://instagram.com/blackbrickproperty"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a
            href="https://ae.linkedin.com/company/blackbrickproperty"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa-brands fa-linkedin-in"></i>
          </a>
        </div>
      </nav>
      <div className="header-listings-button enquire-button-mobile" style={{ padding: "0px" }}>
              <button onClick={handleEnquireClick}>Enquire Now</button>
            </div>
      <div onClick={toggleMenu} className="mobile-menu-trigger">
        <span></span>
        
      </div>
      
      {showPopup && (
        <RegisterForm onClose={handleClosePopup} onSubmitStatus={handleSubmitStatus} />
      )}
    </header>
  );
};

export default Header;
