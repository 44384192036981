import React from "react";
import { convertToCurrency } from "../hooks/currency";
import { Link } from "react-router-dom";

const PropertyCard = ({
  cardLink,
  cardImage,
  cardTitle,
  cardLocation,
  cardBedrooms,
  cardPrice,
  priceOnApplication,
  RefNo

}) => {
  const firstImage = cardImage?.slice(0, 1)?.map((image) => image?.ImageURL) || [];
  const firstImagePortfolio = cardImage?.slice(0, 1)?.map((image) => image);

  console.log("cardLink",cardLink);
  return (
    <div className="property-card">
      <a href={cardLink}>
        <img src={firstImage[0] !== undefined ? firstImage : firstImagePortfolio} alt="property images" />
        <div>
          <h2>{cardTitle?.replace(/\|/g, " | ")}</h2>
          <h4>{cardLocation}</h4>
        </div>
        <div>
        <h3>{cardBedrooms > 0 ? cardBedrooms ===1 ?`${cardBedrooms} Bedroom` : `${cardBedrooms} Bedrooms` : 'Studio'}</h3>
        {cardPrice !== "" && priceOnApplication === "No" ? 
          <h5>AED {convertToCurrency(cardPrice)}</h5> :
          <h5>Price on Application</h5>
        }
      </div>
      </a>
    </div>
  );
};

export default PropertyCard;
