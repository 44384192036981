import React, { useState } from "react";
import styles from "./FormSection.module.css";
import Button from "../Button";
import PhoneInputField from "../PhoneInput";
import { userEnquiry } from "./../../context/endpoints";

const FormSection = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for managing button status

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Disable button on submit

    try {
      const currentURL = window?.location?.href;
    
      // Submit form data using the userEnquiry method
      const data = await userEnquiry(name, email, phoneNumber, `Interested in property type: ${propertyType}`, currentURL);

      if (data.statusCode === 200) {
        setIsSubmitted(true);
        // Handle tracking events like Meta Pixel and Google Analytics if needed
        if (window.fbq) {
          window.fbq("track", "Lead");
        }
        if (window.gtag) {
          window.gtag("event", "subscription", {
            send_to: "G-F4J8CJGY6Y",
          });
        }
      } else {
        console.error("Submission failed:", data);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsSubmitting(false); // Re-enable button after response
    }
  };

  return (
    <section className={styles.formSection}>
      {!isSubmitted && <h2>REGISTER YOUR INTEREST</h2>}
      {isSubmitted ? (
        <div className="success-message">
          <h3>Thank you for your submission!</h3>
          <p>Rest assured, we’ll take it from here. One of our Property Advisors will be in touch shortly to assist you further.</p>
        </div>
      ) : (
        <form className={styles.interestForm} onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <PhoneInputField
            value={phoneNumber} // Pass the phoneNumber state
            onChange={setPhoneNumber} // Update phoneNumber state when it changes
          />
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <select
            value={propertyType}
            onChange={(e) => setPropertyType(e.target.value)}
            required
          >
            <option value="">I'm interested in</option>
            {["Apartment", "Villa", "Townhouse"].map((type) => (
              <option key={type} value={type}>
                {type?.toUpperCase()}
              </option>
            ))}
          </select>
          <Button 
            type="submit" 
            className="submit-contactus" 
            title={"SUBMIT"} 
            disabled={isSubmitting} // Disable button while submitting
          />
        </form>
      )}
    </section>
  );
};

export default FormSection;
