import React, { useState, useRef, useEffect } from 'react';
import { postAccessRequest } from "./../context/endpoints";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const AccessRequestPopup = ({ onClose, onSubmitStatus }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const popupRef = useRef();

  const handleCloseOutside = (event) => {
    if (!popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleCloseOutside);
    return () => {
      document.removeEventListener('mousedown', handleCloseOutside);
    };
  }, []);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const name = `${firstName} ${lastName}`;
      const data = await postAccessRequest(name, email, phoneNumber);
      if (data.statusCode === 200) {
        onSubmitStatus(true, data.result);
      } else {
        onSubmitStatus(false);
      }
      onClose();
    } catch (error) {
      onSubmitStatus(false);
      onClose();
    }
  };

  const handleFormClick = (event) => {
    event.stopPropagation(); // Prevent click event from bubbling up to the popup container
  };

  return (
    <div className="popup-container">
      <div ref={popupRef} className="popup popup-connect">
        <div className="popup-inner">
        <button className="close-btn" onClick={e => onClose()}>X</button> {/* Close button with "X" icon */}
          <div className="popup-content popup-inner" onClick={handleFormClick}>
            <h3>Private Office Access Request</h3>
            <p className="contact-form-instruction">To unlock exclusive access to our Private Office, fill out the below form and one of our Private Office advisors will be in touch</p>
            <form onSubmit={handleSubmit}>
              <div className="contact-form-top-list request-access-form">
                <div className="contact-form-list-item">
                  <label htmlFor="firstName" className="form-label">FIRST NAME:</label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="form-input"
                    required
                  />
                </div>
                <div className="contact-form-list-item">
                  <label htmlFor="lastName" className="form-label">LAST NAME:</label>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="form-input"
                    required
                  />
                </div>
                <div className="contact-form-list-item">
                  <label htmlFor="email" className="form-label">EMAIL:</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-input"
                    required
                  />
                </div>
                <div className="contact-form-list-item" style={{margin: "0px -12px"}}>
                  <label htmlFor="phoneNumber" className="form-label">PHONE:</label>
                  <div className="phone-input-wrapper">
                    <div className="phone-input-inner">
                      <PhoneInput
                        defaultCountry="AE"
                        country="AE"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" className="submit-btn popup-submit-button">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessRequestPopup;
