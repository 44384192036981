import React,{useState,useEffect, useRef} from "react";
import Header from "../components/Header";
import ContactForm from "../components/contact-form";
import Footer from "../components/footer";
import Filter from "../components/properties/filter";

import PropertyCard from "../components/property-card";
import Skeleton from "@mui/material/Skeleton";
import { Helmet } from "react-helmet";
import ErrorMessage from "../components/loading-error/error";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import {  getTeamFn } from "../context/endpoints";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import Box from "@mui/material/Box";
import Popup from "./../components/login-popup"
import AccessRequestPopup from "./../components/access-request-popup"

import {
  getPrivatePropertiesFn,
  getPrivateFeaturedPropertiesFn,
} from "../context/endpoints";
import { useInView } from "react-intersection-observer";
import { useQueryParam, StringParam } from "use-query-params";
import People from "../components/team/people";

const PrivateOffice = () => {
  useEffect(() => {
    // Disable right-click
    const preventRightClick = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', preventRightClick);

    // Disable drag-and-drop
    const preventDragStart = (event) => {
      event.preventDefault();
    };

    document.addEventListener('dragstart', preventDragStart);

    // Clean up event listeners when component unmounts
    return () => {
      document.removeEventListener('contextmenu', preventRightClick);
      document.removeEventListener('dragstart', preventDragStart);
    };
  }, []);



  const { ref, inView } = useInView();
  const [collection, setCollection] = useQueryParam("collection", StringParam);
  const [location, setLocation] = useQueryParam("location", StringParam);
  const [bedrooms, setBedrooms] = useQueryParam("bedrooms", StringParam);
  const [minPrice, setMinPrice] = useQueryParam("minPrice", StringParam);
  const [maxPrice, setMaxPrice] = useQueryParam("maxPrice", StringParam);
  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPropertyPopupOpen, setIsPropertyPopupOpen] = useState(false);

  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [accessRequestSubmissionStatus, setAccessRequestSubmissionStatus] = useState(null);
  const [isRequestAccessPopupOpen, setisRequestAccessPopup] = useState(false);
  const [isPropertyRequestAccessPopupOpen, setisPropertyRequestAccessPopup] = useState(false);

  const [accessId, setAccessId] = useState(false)
  const properttySectionRef = useRef(null);

  useEffect(() => {

    if (submissionStatus === true && properttySectionRef.current) {
      properttySectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [submissionStatus]);

  const { data, error, fetchNextPage, hasNextPage, isFetching, status } =
  useInfiniteQuery({
    queryKey: [
      "PrivatePropertiesData",
      collection,
      location,
      bedrooms,
      minPrice,
      maxPrice,
      accessId
    ],
    queryFn: ({ pageParam = 1 }) =>
    getPrivatePropertiesFn({
        collection,
        location,
        bedrooms,
        minPrice,
        maxPrice,
        pageParam,
        accessId
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.page < lastPage.pages) {
        return lastPage?.page + 1;
      }
      return undefined;
    },
  });

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setisRequestAccessPopup(false);
  };

  const toggleLoginPopup =() =>{
    setIsPropertyPopupOpen(!isPropertyPopupOpen)
    setisRequestAccessPopup(false);

  };

  const toggleRequestAccessPopup = () => {
    setisRequestAccessPopup(!isRequestAccessPopupOpen);
    setIsPopupOpen(false);
    setIsPropertyPopupOpen(false)
  };



  const togglePropertyRequestAccessPopup = () => {
    console.log("req")
    setisPropertyRequestAccessPopup(!isPropertyRequestAccessPopupOpen);
    setIsPopupOpen(false);
    setIsPropertyPopupOpen(false)
  };

  const handleSubmissionStatus = (status) => {
    setSubmissionStatus(status);
  };

  const handleAccessRequestSubmissionStatus = (status,payload) => {
    setAccessRequestSubmissionStatus(status);
    setAccessId(payload?._id)
  };

  // This code to manage infinite scrolling
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const loadingSkeleton = Array.from({ length: 3 }).map((_, index) => (
    <Box key={index} style={{ margin: "60px 0" }}>
      <Skeleton variant="rectangular" width="100%">
        <div style={{ paddingTop: "259px" }} />
      </Skeleton>
      <Skeleton variant="text" width="100%">
        <div style={{ paddingTop: "65px", marginBottom: "-9px" }} />
      </Skeleton>
      <Skeleton variant="text" width="100%">
        <div style={{ paddingTop: "23px" }} />
      </Skeleton>
    </Box>
  ));

 

  const team = useQuery({
    queryKey: ["TeamData"],
    queryFn: () => getTeamFn(true),
  });

  return (
    <div className="private-office-listings">
      <Helmet>
        <title>Private office - BlackBrick</title>
        <meta
          name="description"
          content="Private office - BlackBrick for Developers & Property Owners. Residential/Commercial Real Estate, Hospitality, Yachting & Tourism"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://blackbrickproperty.com/advisory"
        />
        <meta property="og:title" content="Development Advisory - BlackBrick" />
        <meta
          property="og:description"
          content="Private office - BlackBricks for Developers & Property Owners. Residential/Commercial Real Estate, Hospitality, Yachting & Tourism"
        />
        <meta
          property="og:image"
          content="/assets/images/advisory-image3.jpg"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://blackbrickproperty.com/advisory"
        />
        <meta
          property="twitter:title"
          content="Development Advisory - BlackBrick"
        />
        <meta
          property="twitter:description"
          content="Strategic Advisory Services for Developers & Property Owners. Residential/Commercial Real Estate, Hospitality, Yachting & Tourism"
        />
        <meta
          property="twitter:image"
          content="/assets/images/advisory-image3.jpg"
        />
      </Helmet>
      <Header />

      <div className="private-header-container">
        <div className="private-header-titles">
          <div className="container">
            <h1>PRIVATE OFFICE</h1>
            <h2>For Our Most Discerning Clients</h2>
            <p>
            The BlackBrick Private Office exists to cater to the needs of our most discerning clients who value confidentiality and exclusivity. 
            With an unwavering commitment to discretion, our Private Office team will navigate you through the real estate landscape with a refined understanding of the delicate nature of high-profile transactions. 
            As a Private Office client, you will benefit from our portfolio of 'off-market' exclusive and premium listings that are only offered via our Private Office.
            
            Whether you are buying, selling, or investing, our Private Office ensures that your real estate journey remains confidential, allowing you to make decisions with the utmost peace of mind 
            </p>
            {!submissionStatus &&
            <div className="private-btn">
              <div>
                <img className="private-lock-icon" alt="Private office" src ="/assets/images/Asset-2.png"/>
              </div>
              <input className="private-btn" onClick={togglePopup}  type="submit" id="submit" value="I HAVE ACCESS" />
              <input className="private-btn" onClick={togglePropertyRequestAccessPopup} type="submit" id="submit" value="REQUEST ACCESS" />
              {isPopupOpen && <Popup onClose={togglePopup} onSubmitStatus={handleSubmissionStatus} />}
              {submissionStatus !== null && (
                    <p>Access {submissionStatus ? 'Granted' : 'Declined'}</p>
              )}
              {isPropertyRequestAccessPopupOpen && <AccessRequestPopup onClose={togglePropertyRequestAccessPopup} onSubmitStatus={handleAccessRequestSubmissionStatus} />}
              {accessRequestSubmissionStatus !== null && (
                    <p>You request for access {accessRequestSubmissionStatus ? 'Submitted' : 'Failed'}</p>
              )}
            </div>
            }
          </div>
        </div>
      </div>
      <div className="private-container">
        <div className="container">
          <div className="private-texts-container">
            <h3>Our Human Crafted Private office</h3>
            <div className="private-texts">
              <p>
              BlackBrick’s Private Office advisors offer unparalleled expertise. Each member of this elite team is meticulously selected for their exceptional service and knowledge.
              <br></br>
              All of our Private Office advisors embody a dedication to excellence and a passion for crafting bespoke real estate experiences for their clients. 
              <br></br>
              With their deep-rooted knowledge, unwavering commitment, and a robust portfolio of successful transactions, our agents are your pillars of trust and discretion.
              </p>
              
            </div>
            <img
              src="https://res.cloudinary.com/dohzddznt/image/upload/v1706628469/Group_Picture_Hoe_Page_cc6usw.png"
              alt="advisory images"
            />
          </div>
        </div>
        <div className="container">
          <div className="about-container">
            <div className="about-people-container">
              <div className="about-people">
                  <h4 className="slider-title">Private Office Advisors</h4>
                  {team.isPending ? null : team.error ? (
                    <ErrorMessage>{team.error.message}</ErrorMessage>
                  ) : (
                    <Swiper
                      slidesPerView={2}
                      spaceBetween={14}
                      breakpoints={{
                        576: {
                          slidesPerView: 2,
                        },
                        768: {
                          slidesPerView: 3,
                        },
                        992: {
                          slidesPerView: 4,
                        },
                        1200: {
                          slidesPerView: 5,
                        },
                        1400: {
                          slidesPerView: 5,
                        },
                      }}
                      navigation={true}
                      autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay, Navigation]}
                      className="mySwiper"
                    >
                      {team.data
                        .filter((team) => team.feature === false)
                        .map((team, index) => (
                          <SwiperSlide key={index}>
                            <People
                              isActive = {team.isActive}
                              AgentID={team.AgentID}
                              name={team.name}
                              image={team.image}
                              jobTitle={team.jobTitle}
                              email={team.email}
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  )}
              </div>
            </div>
          </div>
        </div>
        {!submissionStatus &&<div className="hide-listing-container">
          <div className="private-header-titles">
          <div className="container hide-container">
            <h2>The following section is password protected!</h2>
            <p>
            To view the private office properties use the access given to you or click request access and one of our private office advisors will get in touch with you.
            </p>
            <div className="private-btn">
            <div>
              <img className="private-lock-icon" alt="Private office" src ="/assets/images/Asset-2.png"/>
            </div>
            <input className="private-btn" onClick={toggleLoginPopup}  type="submit" id="submit" value="I HAVE ACCESS" />
              {isPropertyPopupOpen && <Popup onClose={toggleLoginPopup} onSubmitStatus={handleSubmissionStatus} />}
              {submissionStatus !== null && (
                  <p>Access {submissionStatus ? 'Granted' : 'Declined'}</p>
              )}
              <input className="private-btn" onClick={toggleRequestAccessPopup} type="submit" id="submit" value="REQUEST ACCESS" />
              {isRequestAccessPopupOpen && <AccessRequestPopup onClose={toggleRequestAccessPopup} onSubmitStatus={handleAccessRequestSubmissionStatus} />}
              {accessRequestSubmissionStatus !== null && (
                  <p>You request for access {accessRequestSubmissionStatus ? 'Submitted' : 'Failed'}</p>
              )}
            </div>
          </div>
        </div>
        </div>}
        {submissionStatus &&<section ref={properttySectionRef} className="properties-section">
        <div className="properties-header">
          <div className="container">
            <h1>PRIVATE OFFICE PROPERTIES</h1>
            <p>
              You have the opportunity to explore Lifestyle options, experience the beach and marina,
              or move to the open and expansive golf course communities.
              Search our availability or contact the Team to discuss your options.
            </p>
          </div>
        </div>
        <div className="container">
       
         <div className="properties-listings">
            {status === "pending" ? (
              <>{loadingSkeleton}</>
            ) : status === "error" ? (
              <ErrorMessage>{error.message}</ErrorMessage>
            ) : (
              <>
                {data.pages.map((group, i) => (
                  <React.Fragment key={i}>
                    {group.properties.map((property, index) => (
                      <PropertyCard
                        key={index}
                        cardLink={`/properties/private/property/${property.RefNo}`}
                        cardImage={property.Images?.Image}
                        cardTitle={property.MarketingTitle}
                        cardLocation={property.Community}
                        cardBedrooms={property.Bedrooms}
                        cardPrice={property.SellPrice}
                        priceOnApplication ={property.PriceOnApplication}
                        RefNo={property.RefNo}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </>
            )}
            {isFetching && <>{loadingSkeleton}</>}
            <p ref={ref}></p>
          </div>
        </div>
        </section>}
       

      </div>
      <ContactForm
        formTitle={"our bespoke services?"}
        formSubtitle={"Interested in"}
      />
      <Footer />
    </div>
  );
};

export default PrivateOffice;
