import React from "react";
import styles from "./Button.module.css";

export default function Button({ title, link, type = "button", onClick,className }) {
  if (link) {
    return (
      <a
        href={link}
        className={styles.button}
        target="_blank"
        rel="noopener noreferrer"
      >
        {title}
      </a>
    );
  }

  return (
    <button onClick={onClick} type={type} className={`${styles.button} ${className}`}>
      {title}
    </button>
  );
}
