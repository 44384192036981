import React, { useState, useRef, forwardRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { getSettingsFn } from "../context/endpoints";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ErrorMessage from "./loading-error/error";
import { userEnquiry } from "./../context/endpoints";

const Footer = () => {
  const form = useRef();
  const [open, setOpen] = useState(false);
  const [opensec, setOpensec] = useState(false);
  // EmailJS handler
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all" });

  // Alert function
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // This function to close the snackbar
  const handleClose = () => {
    setOpen(false);
    setOpensec(false);
  };

  // EmailJS mail sender
  const sendEmail = async (formData) => {
    try {
      // Using EmailJS to send email
      await emailjs.sendForm(
        "service_vj32mir",
        "template_t8wu7mb",
        form.current,
        "DFn6ZFihQCr8wgqwz"
      );

      // Using userEnquiry to handle backend logic with email
      await userEnquiry(undefined, formData.email, undefined);

      // Reset form after successful submission
      reset();
      setOpen(true);
      if (window.gtag) {
        window.gtag('event', 'subscription', {
          'send_to': 'G-F4J8CJGY6Y'
        });
      }

    } catch (error) {
      console.error("Failed to send email:", error);
      setOpensec(true);
    }
  };

  // Fetching settings data using useQuery
  const { isPending, error, data } = useQuery({
    queryKey: ["settingsData"],
    queryFn: () => getSettingsFn(),
  });

  // Handling settings data
  let websitesettings = {};
  if (!isPending && !error) {
    websitesettings = Object.assign({}, ...data);
  }

  return (
    <footer>
      <div className="footer-container">
        <div className="footer-wrapper">
          <div className="footer-widget">
            <h3 className="blackbrick-footer-title">BlackBrick Property</h3>
            <div>
              <a
                className="social-icons-footer"
                href="https://www.facebook.com/BlackBrick.ae"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a
                className="social-icons-footer"
                href="https://instagram.com/blackbrickproperty"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                className="social-icons-footer"
                href="https://ae.linkedin.com/company/blackbrickproperty"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa-brands fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div className="footer-widget">
            <h3>Key Links</h3>
            <div className="footer-links">
              <div className="footer-link">
                <Link to="/properties/buy">
                  <h4>Buy</h4>
                </Link>
                <Link to="/properties/rent">
                  <h4>Rent</h4>
                </Link>
                <Link to="/private/office">
                  <h4>Private Office</h4>
                </Link>
                <Link to="/design">
                  <h4>Design</h4>
                </Link>
              </div>
              <div className="footer-link">
                <Link to="/bond">
                  <h4>Bond</h4>
                </Link>
                <Link to="/journal">
                  <h4>Journal</h4>
                </Link>
                <Link to="/advisory">
                  <h4>Advisory</h4>
                </Link>
                <Link to="/about">
                  <h4>About</h4>
                </Link>
                <Link to="/contact">
                  <h4>Contact</h4>
                </Link>
              </div>
            </div>
          </div>
          <div className="footer-widget">
            <Link to="/contact">
              <h3>Contact</h3>
            </Link>
            <div className="footer-address">
              <h4>Zabeel House</h4>
              <h4>Unit 1114</h4>
              <h4>Onyx Tower 2</h4>
              <h4>Dubai, UAE</h4>
            </div>
            {isPending ? null : error ? (
              <ErrorMessage>{error.message}</ErrorMessage>
            ) : (
              <>
                <div className="footer-email">
                  <a
                    href={`mailto:${websitesettings.email}`}
                    rel="noopener noreferrer"
                  >
                    <h4>{websitesettings.email}</h4>
                  </a>
                </div>
                <div className="footer-phone">
                  <a
                    href={`tel:${websitesettings.phone}`}
                    rel="noopener noreferrer"
                  >
                    <h4>{websitesettings.phone}</h4>
                  </a>
                </div>
              </>
            )}
          </div>
          <div className="footer-widget">
            <div className="footer-subscribe">
              <h3>Subscribe</h3>
              <p>
                Subscribe to our newsletter to hear about exclusive properties
                and news.
              </p>
              <form ref={form} onSubmit={handleSubmit(sendEmail)}>
                {errors.email && <h6 role="alert">Email must be valid</h6>}
                <label htmlFor="email">Email:</label>
                <input
                  {...register("email", {
                    required: "Email is Required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Email must be valid",
                    },
                  })}
                  type="email"
                  id="subscribe"
                  name="email"
                  size="25"
                />
                <button type="submit">Subscribe</button>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <h5>© Copyright BlackBrick {new Date().getFullYear()}</h5>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Message has been sent successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={opensec} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Failed to send the message!
        </Alert>
      </Snackbar>
    </footer>
  );
};

export default Footer;
