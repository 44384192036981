import React from "react";
import { Link } from "react-router-dom";

const HomeText = ({ h1Content = "", h2Content = "", onEnquireClick }) => {
  return (
    <div className="container">
      <div className="home-text">
        <h1>
          {h1Content ||
            "BlackBrick sells and rents exclusive homes in the most sought-after Lifestyle Destinations in Dubai. We connect international and local clients to the most luxurious, design-led villas and apartments in Dubai's premium communities."}
        </h1>
        <div className="home-listings-button">
          <button onClick={onEnquireClick}>Enquire Now</button>
        </div>
        <h2>
          {h2Content ||
            "We are a value-driven service platform in Real Estate where people come first. Our purpose is to fundamentally change the RE brokerage model and reputation, creating tangible value for all stakeholders, delivering a unique personal service that thrives on passion, empathy, transparency and integrity."}
        </h2>
        <Link to="/about">
          <p className="read-more">Read more {">"}</p>
        </Link>
      </div>
    </div>
  );
};

export default HomeText;
